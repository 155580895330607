import { notification } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import NewNodeModal from './NewNodeModal';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmModal from './DeleteConfirmModal';
import { Constants } from './common/constants';
import useFetchWithAuth from '../utils/fetchWithAuth';
const { REACT_APP_CORE_URL } = Constants;

const NewVillage = () => {
  const [villageName, setVillageName] = useState('');
  const [villageCode, setVillageCode] = useState('');
  const [district, setDistrict] = useState('');
  const [pincode, setPincode] = useState('');
  const [state, setState] = useState('Jammu & Kashmir');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');

  const [nodes, setNodes] = useState([]);

  const [error, setError] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const villageData = location?.state?.villageData;
  const fetchWithAuth = useFetchWithAuth();

  const columns = useMemo(() => [
    {
      accessorKey: 'node_id', //access nested data with dot notation
      header: 'Id',
      size: 50
    },
    {
      accessorKey: 'n_name', //access nested data with dot notation
      header: 'Name',
      size: 50
    },
    {
      accessorKey: 'n_lat', //access nested data with dot notation
      header: 'Lattitude',
      size: 50
    },

    {
      accessorKey: 'n_long', //access nested data with dot notation
      header: 'Longitude',
      size: 50
    },
    {
      accessorKey: 'node_description', //access nested data with dot notation
      header: 'Description',
      size: 50
    },

    {
      accessorKey: 'actions', //access nested data with dot notation
      header: 'Action',
      size: 50,
      accessorFn: (row) => (
        <div className="flex">
          <NewNodeModal
            triggerText={
              <EditIcon className="text-gray-800 dark:text-gray-200" />
            }
            villageId={params.villageId}
            getNodes={getNodeDetails}
            nodeDetails={{
              nodeId: row.node_id,
              name: row.n_name,
              lat: row.n_lat,
              long: row.n_long,
              desc: row.node_description
            }}
          />

          <div className="cursor-pointer">
            <DeleteConfirmModal handleDelete={() => deleteNode(row.node_id)} />
          </div>
        </div>
      )
    }
  ]);

  const table = useMaterialReactTable({
    columns,
    data: nodes,
    initialState: {
      density: 'compact',
      columnVisibility: {
        v_pincode: false
      }
    },
    enableDensityToggle: false,
    // enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false
  });

  useEffect(() => {
    if (villageData) {
      setVillageName(villageData.v_name);
      setVillageCode(villageData.v_code);
      setDistrict(villageData.v_district);
      setPincode(villageData.v_pincode);
      setState(villageData.v_state);
      setLat(villageData.v_lat);
      setLong(villageData.v_long);
      getNodeDetails();
    }
  }, []);

  const deleteNode = (id) => {
    if (
      villageData?.village_id == 'V7735' ||
      villageData?.village_id == 'V4433' ||
      villageData?.village_id == 'V1000'
    ) {
      notification.warning({
        message: 'You are not authorised to delete this Village!'
      });
      return;
    }
    fetchWithAuth(`${REACT_APP_CORE_URL}/deleteNode/${id}`, {
      method: 'DELETE',
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode == 204) {
          notification.success({ message: 'Node Deleted!!' });
          getNodeDetails();
        }
      })
      .catch((err) => console.log(err));
  };

  const getNodeDetails = () => {
    fetchWithAuth(`${REACT_APP_CORE_URL}/getNode`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        q: {
          village_id: params.villageId
        }
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode == 200) {
          setNodes(data.result);
        }
      })
      .catch((err) => console.log(err));
  };

  const createVillage = () => {
    if (
      villageName.length == 0 ||
      pincode.length == 0 ||
      villageCode.length == 0 ||
      lat.length == 0 ||
      long.length == 0 ||
      state.length == 0 ||
      district.length == 0
    ) {
      setError(true);
      return;
    }

    const query = {};
    query.v_name = villageName;
    query.v_pincode = pincode;
    query.v_code = villageCode;
    query.v_lat = parseFloat(lat);
    query.v_long = parseFloat(long);
    query.v_state = state;
    query.v_district = district;

    fetchWithAuth(`${REACT_APP_CORE_URL}/createVillageDetails`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(query)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode == 201) {
          notification.success({
            message: 'Village Created!!'
          });
        }
      })
      .then(() => navigate('/villages'))
      .catch((err) => console.log(err));
  };

  const updateVillage = () => {
    if (
      villageName.length == 0 ||
      pincode.length == 0 ||
      villageCode.length == 0 ||
      lat.length == 0 ||
      long.length == 0 ||
      state.length == 0 ||
      district.length == 0
    ) {
      setError(true);
      return;
    }

    const query = {};
    query.v_name = villageName;
    query.v_pincode = pincode;
    query.v_code = villageCode;
    query.v_lat = parseFloat(lat);
    query.v_long = parseFloat(long);
    query.v_state = state;
    query.v_district = district;

    fetchWithAuth(
      `${REACT_APP_CORE_URL}/updateVillageDetails/${params.villageId}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(query)
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode == 200) {
          notification.success({
            message: 'Village Updated!!'
          });
        }
      })
      .then(() => {
        navigate('/villages');
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (params.villageId) {
      updateVillage();
    } else {
      createVillage();
    }
  };

  console.log(params.villageId);

  return (
    <div>
      <form className="w-full ">
        <div className="flex flex-wrap mb-6">
          <div className="w-full md:w-1/3  px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Village Name
            </label>
            <input
              className={`appearance-none block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
                error && villageName.length == 0 ? ' border border-red-500' : ''
              }`}
              id="grid-first-name"
              type="text"
              placeholder="Mandi"
              value={villageName}
              onChange={(e) => setVillageName(e.target.value)}
            />
            {error && villageName.length == 0 ? (
              <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide  text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Village Code
            </label>
            <input
              className={`appearance-none block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
                error && villageCode.length == 0 ? ' border border-red-500' : ''
              }`}
              id="grid-first-name"
              type="number"
              placeholder="193122"
              value={villageCode}
              onChange={(e) => setVillageCode(e.target.value)}
            />
            {error && villageCode.length == 0 ? (
              <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3">
            <label
              className="block uppercase tracking-wide  text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Disctrict
            </label>
            <input
              className={`appearance-none block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
                error && district.length == 0 ? ' border border-red-500' : ''
              }`}
              id="grid-last-name"
              type="text"
              placeholder="Poonch"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            />
            {error && district.length == 0 ? (
              <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3">
            <label
              className="block uppercase tracking-wide  text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Pincode
            </label>
            <input
              className={`appearance-none block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
                error && pincode.length == 0 ? ' border border-red-500' : ''
              }`}
              id="grid-last-name"
              type="number"
              placeholder="175124"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
            {error && pincode.length == 0 ? (
              <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p>
            ) : null}
          </div>
          <div className="w-full md:w-1/3 px-3">
            <label
              className="block uppercase tracking-wide  text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              State
            </label>
            <input
              className={`appearance-none block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
                error && state.length == 0 ? ' border border-red-500' : ''
              }`}
              id="grid-last-name"
              type="text"
              placeholder="Jammu & Kashmir"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            {error && state.length == 0 ? (
              <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p>
            ) : null}
          </div>{' '}
          <div className="w-full md:w-1/3 px-3">
            <label
              className="block uppercase tracking-wide  text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Latitude
            </label>
            <input
              className={`appearance-none block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
                error && lat.length == 0 ? ' border border-red-500' : ''
              }`}
              id="grid-last-name"
              type="number"
              placeholder="31.7087"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
            />
            {error && lat.length == 0 ? (
              <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p>
            ) : null}
          </div>{' '}
          <div className="w-full md:w-1/3 px-3">
            <label
              className="block uppercase tracking-wide  text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Longitude
            </label>
            <input
              className={`appearance-none block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
                error && long.length == 0 ? ' border border-red-500' : ''
              }`}
              id="grid-last-name"
              type="number"
              placeholder="76.9320"
              value={long}
              onChange={(e) => setLong(e.target.value)}
            />
            {error && long.length == 0 ? (
              <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p>
            ) : null}
          </div>
        </div>
      </form>
      {params?.villageId ? (
        <div className="px-3">
          <div className="flex justify-between">
            <div className="text-xl  font-bold">Nodes</div>.
            <NewNodeModal
              triggerText={
                <button
                  type="button"
                  className="bg-green-500 text-white px-2 py-1 rounded"
                  // onClick={() => setShowModal(true)}
                >
                  New Node
                </button>
              }
              villageId={params.villageId}
              getNodes={getNodeDetails}
            />
          </div>
          <MaterialReactTable table={table} />
        </div>
      ) : null}

      <div className="px-3 flex justify-end mt-3">
        <Link
          to="/villages"
          className="px-2 py-1 border-2 border-gray-400 text-gray-600 dark:text-gray-200 rounded mr-2"
        >
          Back
        </Link>
        <button
          onClick={handleSubmit}
          className="bg-blue-600 text-white px-2 py-1 text-lg rounded "
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default NewVillage;
