import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../../src/Styles/DataModal.css'; // We'll use an external CSS file for better styling
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
Modal.setAppElement('#root');
const DataModal = ({ isOpen, onClose, rowData }) => {
  const [isNodeDetailsOpen, setNodeDetailsOpen] = useState(false);
  const [isUserInfoOpen, setUserInfoOpen] = useState(false);
  const toggleNodeDetails = () => setNodeDetailsOpen(!isNodeDetailsOpen);
  const toggleUserInfo = () => setUserInfoOpen(!isUserInfoOpen);
  useEffect(() => {
    const headerElement = document.querySelector('.header');
    if (headerElement) {
      const headerHeight = headerElement.offsetHeight;
      // Set a CSS variable dynamically based on the header height
      document.documentElement.style.setProperty(
        '--header-height',
        `${headerHeight + 20}px`
      );
      document.documentElement.style.setProperty(
        '--modal-max-height',
        `calc(100vh - ${headerHeight + 40}px)`
      );
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Row Details"
      className="data-modal" // Custom modal class
      overlayClassName="data-modal-overlay" // Custom overlay class
      style={{ maxHeight: '80vh', overflowY: 'auto' }}
    >
      <div className={isOpen ? 'modal-content' : ''}>
        {/* User Image Section */}
        <div className="image-section">
          {rowData.imagebase64 ? (
            <img src={rowData.imagebase64} alt="User" className="user-image" />
          ) : (
            <p>No Image Available</p>
          )}
        </div>
        {console.log(
          rowData.userInfo?.imagebase64?.split('data:image/png;base64,')[1]
        )}
        {/* Details Section */}
        <div className="details-section">
          <h2>
            {rowData.userInfo?.f_name?.charAt(0).toUpperCase() +
              rowData.userInfo?.f_name?.slice(1).toLowerCase()}
            {rowData.userInfo?.l_name?.charAt(0).toUpperCase() +
              rowData.userInfo?.l_name?.slice(1).toLowerCase()}
          </h2>
          <p>
            <strong>Entry/Exit:</strong> {rowData.entry_or_exit}
          </p>
          <p>
            <strong>Entry/Exit Date & Time:</strong>{' '}
            {new Date(rowData.entry_or_exit_at).toLocaleString()}
          </p>
          <p>
            <strong>Purpose of Visit:</strong>{' '}
            {rowData.purpose_of_visit || 'N/A'}
          </p>
          <p>
            <strong>Reason:</strong> {rowData.purpose_of_visit || 'N/A'}
          </p>
          <p>
            <strong>Remarks:</strong> {rowData.purpose_of_visit || 'N/A'}
          </p>
          <p>
            <strong>Vehicle Make Model:</strong>{' '}
            {rowData.vehicle_make_model || 'N/A'}
          </p>
          <p>
            <strong>Vehicle Number:</strong> {rowData.vehicle_number || 'N/A'}
          </p>
          <p>
            <strong>Vehicle Remarks:</strong> {rowData.vehicle_remarks || 'N/A'}
          </p>
          <p>
            <strong>Vehicle Type:</strong> {rowData.vehicle_type || 'N/A'}
          </p>
          <p>
            <strong>Entry Village :</strong> {rowData.village?.v_name || 'N/A'}
          </p>
          <div className="section">
            <h2 onClick={toggleNodeDetails} className="section-header">
              Node Details{' '}
              {isNodeDetailsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </h2>

            {isNodeDetailsOpen && (
              <div className="section-content">
                <p>
                  <strong>Node Name:</strong> {rowData.nodeDetails?.n_name}
                </p>
                <p>
                  <strong>Node ID:</strong> {rowData.nodeDetails?.node_id}
                </p>
                <p>
                  <strong>Latitude:</strong> {rowData.nodeDetails?.n_lat}
                </p>
                <p>
                  <strong>Longitude:</strong> {rowData.nodeDetails?.n_long}
                </p>
              </div>
            )}
          </div>
          <div className="section">
            <h2 onClick={toggleUserInfo} className="section-header">
              User Information{' '}
              {isUserInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </h2>

            {isUserInfoOpen && (
              <div className="section-content">
                <p>
                  <strong>Aadhar number:</strong> {rowData.userInfo?.aadhar}
                </p>
                <p>
                  <strong>Gender:</strong> {rowData.userInfo?.gender}
                </p>
                <p>
                  <strong>Address:</strong> {rowData.userInfo?.address}
                </p>
                <p>
                  <strong>Category:</strong> {rowData.userInfo?.category}
                </p>
                <p>
                  <strong>Village:</strong> {rowData.userInfo?.village?.v_name}
                </p>
                <p>
                  <strong>City:</strong> {rowData.userInfo?.city}
                </p>
                <p>
                  <strong>State:</strong> {rowData.userInfo?.state}
                </p>
                <p>
                  <strong>Country:</strong> {rowData.userInfo?.country}
                </p>
                <p>
                  <strong>Pincode:</strong> {rowData.userInfo?.pincode}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <button onClick={onClose} className="close-button">
        Close
      </button>
    </Modal>
  );
};

export default DataModal;
