import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  TextField,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { Constants } from './common/constants';
import { toast } from 'react-toastify';
import PageTitle from './common/pageTitle';

const { REACT_APP_CORE_URL } = Constants;

const UserRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fetchWithAuth = useFetchWithAuth();
  const { state } = location;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    usernamePassword: '',
    reason: ''
  });
  const [userPasswordPairs, setUserPasswordPairs] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchUserCreds = async () => {
      try {
        const res = await fetchWithAuth(`${REACT_APP_CORE_URL}/getUserCreds`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await res.json();

        if (data.statusCode === 200 && data.data?.length > 0) {
          setUserPasswordPairs(data.data);
        } else {
          console.error('No credentials found for dropdown');
        }
      } catch (err) {
        console.error('Error fetching credentials for dropdown:', err);
      }
    };

    fetchUserCreds();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (state) {
        const { email, firstName, mode, userId } = state;
        if (mode === 'edit' && firstName) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            name: firstName,
            email: email
          }));

          try {
            const res = await fetchWithAuth(
              `${REACT_APP_CORE_URL}/getAuthUsers`,
              {
                method: 'POST',
                credentials: 'include',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  q: {
                    id: userId
                  }
                })
              }
            );

            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }

            const data = await res.json();
            setTableData(data.data || []);
          } catch (err) {
            console.error('Error fetching credential data:', err);
          }
        }
      }
    };

    fetchData();
  }, [state]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));

    if (name === 'email' && value.trim()) {
      try {
        const res = await fetchWithAuth(`${REACT_APP_CORE_URL}/getAuthUsers`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            q: {
              email: value
            }
          })
        });

        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }

        const data = await res.json();
        if (data.data && Array.isArray(data.data) && data.data.length > 0) {
          toast.error(
            'This ID is already registered. Please use a different ID.'
          );
        }
      } catch (err) {
        console.error('Error fetching user details:', err);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name.trim()) {
      toast.error('Name is required');
      return;
    }

    if (!formData.email.trim()) {
      toast.error('User ID is required');
      return;
    }

    if (!formData.usernamePassword) {
      toast.error('Please ensure that credentials data is entered');
      return;
    }

    if (formData.usernamePassword && !formData.reason.trim()) {
      toast.error('Please provide a reason for the credentials');
      return;
    }

    const updatedFormData = state?.userId
      ? { ...formData, userId: state.userId }
      : { ...formData };

    const formDataJSON = JSON.stringify(updatedFormData);

    try {
      const response = await fetchWithAuth(
        `${REACT_APP_CORE_URL}/createOrUpdateAuthUser`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: formDataJSON
        }
      );

      const data = await response.json();
      if (response.status === 400) {
        toast.error(
          `${data.message || 'An error occurred while creating the user.'}`
        );
      }
      navigate('/auth-users');
    } catch (error) {
      console.error(
        `Error ${state?.userId ? 'updating' : 'creating'} user:`,
        error
      );
    }
  };

  const inputStyle = {
    backgroundColor: '#fff',
    boxShadow: 2,
    borderRadius: 1,
    '&:focus-within': {
      boxShadow: 3,
      borderColor: '#3f51b5'
    }
  };

  return (
    <div>
      <AppBar position="static" color="transparent" sx={{ boxShadow: 'none' }}>
        <Toolbar>
          <PageTitle title="Add Auth User" />
          <Box sx={{ position: 'absolute', right: 16, top: 16 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'green',
                color: 'white',
                boxShadow: 'none',
                borderRadius: 2
              }}
              type="submit"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Grid container spacing={2} sx={{ marginTop: 0, padding: 0 }}>
        <Grid item xs={12} md={6}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ padding: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#3B82F6',
                textAlign: 'left',
                marginBottom: 3,
                fontFamily: 'Cambria, serif'
              }}
            >
              User Details
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  sx={inputStyle}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="User ID"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  sx={inputStyle}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontWeight: 'bold',
                    color: '#3B82F6',
                    marginTop: 3,
                    fontFamily: 'Cambria, serif'
                  }}
                >
                  Credentials
                </Typography>

                <FormControl fullWidth margin="normal">
                  <InputLabel id="combo-label">Username-Password</InputLabel>
                  <Select
                    labelId="combo-label"
                    name="usernamePassword"
                    value={formData.usernamePassword}
                    onChange={handleChange}
                    renderValue={(value) => value || 'Select a combination'}
                    sx={inputStyle}
                  >
                    <MenuItem value="">
                      <em>--none--</em>
                    </MenuItem>
                    {userPasswordPairs?.map((pair) => (
                      <MenuItem
                        key={`${pair.username}:${pair.passwordPlain}`}
                        value={`${pair.username}:${pair.passwordPlain}`}
                      >
                        {`${pair.username}:${pair.passwordPlain}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Reason"
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  sx={inputStyle}
                  required={Boolean(formData.usernamePassword)}
                  error={Boolean(formData.usernamePassword && !formData.reason)}
                  helperText={
                    Boolean(formData.usernamePassword && !formData.reason)
                      ? 'This is required'
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ marginBottom: 2 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                marginBottom: 1,
                color: '#3B82F6',
                fontFamily: 'Cambria, serif'
              }}
            >
              Assigned Credentials
            </Typography>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: 400, overflow: 'auto' }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        fontFamily: 'Cambria, serif'
                      }}
                    >
                      Username
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        fontFamily: 'Cambria, serif'
                      }}
                    >
                      Password
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        fontFamily: 'Cambria, serif'
                      }}
                    >
                      Reason
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.length > 0 ? (
                    tableData.map((row, index) =>
                      row.userCredentials.length > 0 ? (
                        row.userCredentials.map((cred, credIndex) => (
                          <TableRow key={`${index}-${credIndex}`}>
                            <TableCell
                              sx={{
                                fontSize: '1rem',
                                fontFamily: 'Roboto, sans-serif'
                              }}
                            >
                              {cred.username}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: '1rem',
                                fontFamily: 'Roboto, sans-serif'
                              }}
                            >
                              {cred.passwordPlain}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: '1rem',
                                fontFamily: 'Roboto, sans-serif'
                              }}
                            >
                              {cred.reason || 'N/A'}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow key={index}>
                          <TableCell colSpan={3}>
                            No credentials available
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>No data available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserRegister;
