export const callbookDummyData = [
  {
    'Person Name': 'Mandi',
    'Belong to village': 'Mandi',
    'Contacted Mobile No': '+91-98765-43210',
    'Incoming Call': '2',
    'Outgoing Call': '',
    Duration: '',
    'Person Contacted': '',
    'Contacted person Village': '',
    'Contacted person Mobile No': ''
  },
  {
    'Person Name': 'Imran Malik',
    'Belong to village': 'Mandi',
    'Contacted Mobile No': '+91 87654-32109',
    'Incoming Call': '',
    'Outgoing Call': '00:15:00',
    'Person Contacted': 'Irfan Khan',
    'Contacted person Village': 'Khan Shahib',
    'Contacted person Mobile No': '+91 98765-43210'
  },
  {
    'Person Name': 'Imran Malik',
    'Belong to village': 'Mandi',
    'Contacted Mobile No': '+91 76543-21098',
    'Incoming Call': '00:12:00',
    'Outgoing Call': '00:12:00',
    'Person Contacted': 'Asif Ansati',
    'Contacted person Village': 'Mandi',
    'Contacted person Mobile No': '+91 87654-32109'
  },
  {
    'Person Name': 'Imran Malik',
    'Belong to village': 'Mandi',
    'Contacted Mobile No': '+91 54321-09876',
    'Incoming Call': '00:12:00',
    'Outgoing Call': '00:12:00',
    'Person Contacted': 'Saif Hussain',
    'Contacted person Village': 'Chennai',
    'Contacted person Mobile No': '+91 89012-34567'
  },
  {
    'Person Name': 'Imran Malik',
    'Belong to village': 'Mandi',
    'Contacted Mobile No': '+91 89012-34567',
    'Incoming Call': '00:12:00',
    'Outgoing Call': '00:11:00',
    'Person Contacted': 'Bilal Sheikh',
    'Contacted person Village': 'Khan Shahib',
    'Contacted person Mobile No': '+91 87654-32109'
  },
  {
    'Person Name': 'Imran Malik',
    'Belong to village': 'Mandi',
    'Contacted Mobile No': '+91 67890-12345',
    'Incoming Call': '00:12:00',
    'Outgoing Call': '00:14:00',
    'Person Contacted': 'Zain Qureshi',
    'Contacted person Village': 'Khan Shahib',
    'Contacted person Mobile No': '+91 87654-32109'
  },
  {
    'Person Name': 'Zain Qureshi',
    'Belong to village': 'Khashahip',
    'Contacted Mobile No': '+91 12345-67890',
    'Incoming Call': '00:12:00',
    'Outgoing Call': '00:08:30',
    'Person Contacted': 'Salman Khan',
    'Contacted person Village': 'Khan Shahib',
    'Contacted person Mobile No': '+91-98765-43210'
  },
  {
    'Person Name': 'Zain Qureshi',
    'Belong to village': 'Khashahip',
    'Contacted Mobile No': '+91 45678-90123',
    'Incoming Call': '10:09:32',
    'Outgoing Call': '00:10:30',
    'Person Contacted': 'Nadeem Khan',
    'Contacted person Village': 'Khashahip',
    'Contacted person Mobile No': '+91 12345-67890'
  },
  {
    'Person Name': 'Zain Qureshi',
    'Belong to village': 'Khashahip',
    'Contacted Mobile No': '+91 90123-45678',
    'Incoming Call': '00:12:00',
    'Outgoing Call': '00:13:00',
    'Person Contacted': 'Imran Malik',
    'Contacted person Village': 'Chennai',
    'Contacted person Mobile No': '+91 54321-09876'
  }
];
