import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import axios from 'axios';
import { Constants } from './common/constants';
import AuthContext from '../context/authContext';
import { logoutAndRedirect } from '../utils/Logout';

const { REACT_APP_CORE_URL } = Constants;

export function useAxiosInstance() {
  const navigate = useNavigate();
  const { setAuthenticated } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL: REACT_APP_CORE_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  axiosInstance.interceptors.request.use((config) => {
    if (config.method === 'post' && config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    const token = localStorage.getItem('user');
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401) {
          logoutAndRedirect(navigate, setAuthenticated);
          console.error('Unauthorized:', data);
        } else if (status === 404) {
          console.error('Not Found:', data);
        } else if (status >= 400 && status < 500) {
          console.error('Client Error:', data);
        } else if (status >= 500) {
          console.error('Server Error:', data);
        }
      } else if (error.request) {
        console.error('Request Error:', error.request);
      } else {
        console.error('Error:', error.message);
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
}
