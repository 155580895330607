import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Spin, notification, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { Constants } from './common/constants';
import PageTitle from './common/pageTitle';

const { REACT_APP_CORE_URL } = Constants;

const AuthUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchWithAuth = useFetchWithAuth();
  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150
      },
      {
        accessorKey: 'email',
        header: 'ID',
        size: 150
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 150,
        accessorFn: (row) => (
          <div className="flex">
            <EditIcon
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(row);
              }}
              className="text-gray-800 dark:text-white cursor-pointer"
            />
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation();
                deleteUser(row.id);
              }}
              className="text-red-600 cursor-pointer ml-2"
            />
          </div>
        )
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: users,
    initialState: {
      density: 'compact',
      columnVisibility: {
        email: true
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (!loading) {
          handleEdit(row.original);
        }
      }
    }),
    enableHiding: false,
    enableDensityToggle: false,
    enablePagination: false,
    enableBottomToolbar: false
  });

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await fetchWithAuth(
        `${REACT_APP_CORE_URL}/getAuthUsers`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        setUsers(data.data);
      } else {
        console.error('Error fetching users:', data.message);
      }
    } catch (err) {
      console.error('Error fetching users:', err);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    if (window.confirm('Are you sure you want to delete this auth user?')) {
      setLoading(true);
      try {
        const res = await fetchWithAuth(
          `${REACT_APP_CORE_URL}/deleteAuthUser/${id}`,
          {
            method: 'DELETE',
            credentials: 'include'
          }
        );
        const data = await res.json();
        if (data.statusCode === 200) {
          notification.success({ message: 'Auth User Deleted Successfully!' });
          await getUsers();
        } else {
          notification.error({ message: 'Error deleting user!' });
        }
      } catch (err) {
        notification.error({ message: 'Something went wrong!' });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (user) => {
    navigate('/user-register', {
      state: {
        email: user.email,
        firstName: user.name,
        userId: user.id,
        mode: 'edit'
      }
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col p-4" style={{ overflow: 'visible' }}>
        <PageTitle title="Auth Users" />
        <div className="flex justify-end mb-2">
          <Link to="/user-register">
            <Button
              type="primary"
              style={{
                backgroundColor: '#2CD074'
              }}
              className="text-lg"
            >
              Add User
            </Button>
          </Link>
        </div>
        <MaterialReactTable table={table} />
      </div>
    </Spin>
  );
};

export default AuthUsers;
