import React, { useState, useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { Spin } from 'antd';
import { Constants } from './common/constants';
import { toast } from 'react-toastify';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { ClearIcon } from '@mui/x-date-pickers';
import * as XLSX from 'xlsx';
import PageTitle from './common/pageTitle';
const { REACT_APP_CORE_URL } = Constants;

const Inventory = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [rowData, setRowData] = useState([]);
  const [columns, setColumns] = useState([]);
  const fetchWithAuth = useFetchWithAuth();

  // to fetch data on load
  useEffect(() => {
    getXlsxData();
  }, []);

  // method to fetch inventory data
  const getXlsxData = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/get-inventory`, {
      method: 'GET',
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        console.log('hello world');
        const reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onload = (event) => {
          const arrayBuffer = event.target.result;
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          const processedData = jsonData.map((item) => {
            return Object.fromEntries(
              Object.entries(item).map(([key, value]) => [
                key.replace(/[^a-zA-Z0-9]/g, '_'), // Replace non-alphanumeric characters
                value
              ])
            );
          });
          const newColumns =
            processedData.length > 0
              ? Object.keys(processedData[0]).map((key) => ({
                  accessorKey: key,
                  header: key.charAt(0).toUpperCase() + key.slice(1),
                  size: 150
                }))
              : [];

          setRowData(processedData);
          setColumns(newColumns);
        };
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const table = useMaterialReactTable({
    columns: columns,
    initialState: {
      density: 'compact',
      columnVisibility: {
        aadhar: false,
        // user_id: false,
        city: false,
        country: false,
        wanted: false
      }
    },
    enableHiding: false,
    enableDensityToggle: false,
    data: rowData,
    enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false
  });

  const handleFileUpload = (e) => {
    setLoading(true);
    const files = e.target.files;
    if (files) {
      setFile(e.target.files[0]);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      fetchWithAuth(`${REACT_APP_CORE_URL}/add-inventory`, {
        method: 'POST',
        credentials: 'include',
        body: formData
      })
        .then(() => {
          toast.success('Uploaded inventory successfully');
          setFile();
          getXlsxData();
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to upload inventory');
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <PageTitle title="Inventory Management" />
      <div className="flex justify-between">
        {/* Uncomment below for upload feature */}
        {/* <div> 
          <label
            htmlFor="fileInput"
            style={{
              cursor: 'pointer',
              backgroundColor: '#2CD074',
              border: '1px solid #2CD074'
            }}
            className="custom-button"
          >
            Upload
          </label>
          <input
            id="fileInput"
            style={{ display: 'none' }}
            type="file"
            // accept=".csv,.xlsx,.xls,.zip"
            onChange={handleFileUpload}
          />
          {file && (
            <div
              style={{ margin: '5px', display: 'flex', alignItems: 'center' }}
            >
              {file?.name}{' '}
              <ClearIcon
                onClick={() => setFile()}
                style={{
                  border: '1px solid black',
                  margin: '0px 10px 0px 10px',
                  borderRadius: '3px'
                }}
              />
            </div>
          )}
        </div> */}
      </div>
      <div id="peopleManagementTable">
        <MaterialReactTable table={table} />
      </div>
    </Spin>
  );
};

export default Inventory;
