import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from 'antd';
import { useState } from 'react';

const DeleteConfirmModal = ({ handleDelete }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const renderFooter = () => (
    <div>
      <button className="border-2  rounded  px-2 py-1" onClick={handleClose}>
        Cancel
      </button>
      <button
        onClick={() => {
          handleDelete();
          handleClose();
        }}
        className="bg-red-600 text-white  px-2 py-1 rounded ml-2"
      >
        Delete
      </button>
    </div>
  );

  return (
    <div>
      <Modal open={showModal} closeIcon={null} footer={renderFooter}>
        <div className="text-lg">
          Do you want the delete this item permanantly?
        </div>
      </Modal>
      <DeleteIcon className="text-red-500" onClick={() => setShowModal(true)} />
    </div>
  );
};

export default DeleteConfirmModal;
