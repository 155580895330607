import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions
} from '@mui/material';
import { Spin } from 'antd';
import axios from 'axios';
import { ThemeContext } from '../index';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Constants } from './common/constants';
import { toast } from 'react-toastify';
import { useAxiosInstance } from './Interceptor';
import PageTitle from './common/pageTitle';
const { FLASK_API_URL } = Constants;

const Reconciliation = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  let noMoreUsersLeft;
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [noFacesFound, setNoFacesFound] = useState(false);
  const [currentDataLength, setCurrentDataLength] = useState(0);
  const [currentInd, setCurrentInd] = useState(1);
  const axiosInstance = useAxiosInstance();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const fetchSimilarFaces = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${FLASK_API_URL}/similar_faces`);
      const similarFaces = response.data.similar_faces.map((pair) => ({
        oid: pair[0],
        nid: pair[1]
      }));
      if (similarFaces.length === 0) {
        setNoFacesFound(true);
      } else {
        setData(similarFaces);
        setCurrentDataLength(similarFaces.length);
        console.log(similarFaces.length);
        await loadUserData();
      }
      setData(similarFaces);
      console.log(similarFaces.length);
    } catch (error) {
      setError(error.message);
      toast.error('Internal Server Error');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async (id) => {
    try {
      const response = await axiosInstance.get(`/getUserInfoById/${id}`);
      if (response.status === 200 && response.data.result) {
        return response.data.result;
      } else {
        return null;
      }
    } catch (err) {
      console.error(`Failed to fetch data for id: ${id}`, err);
      return null;
    }
  };

  const loadUserData = async () => {
    if (!data) return;

    setLoading(true);
    setError(null);

    const ids = data;

    for (let i = currentIndex; i < ids.length; i++) {
      const { oid, nid } = ids[i];

      const oidResponse = await fetchUserData(oid);
      const nidResponse = await fetchUserData(nid);

      if (oidResponse && nidResponse) {
        let oldUserInfo, newUserInfo;
        if (
          new Date(oidResponse.user_created_at) <
          new Date(nidResponse.user_created_at)
        ) {
          oldUserInfo = oidResponse;
          newUserInfo = nidResponse;
        } else {
          oldUserInfo = nidResponse;
          newUserInfo = oidResponse;
        }

        const formattedResult = {
          oid,
          nid,
          oldUserInfo,
          newUserInfo
        };

        setCurrentIndex(i);
        setCurrentInd(i + 1);
        setUserInfo(formattedResult);
        setLoading(false);
        return;
      }
    }

    setNoFacesFound(true);
    setLoading(false);
  };

  useEffect(() => {
    console.log('load');
    loadUserData();
  }, [currentIndex, data]);

  const handleNext = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setNoFacesFound(true);
      setError('No more users to load.');
    }
  };

  const handleOld = async () => {
    try {
      const currentItem = userInfo;
      const requestBody = {
        existingUserId: currentItem.oid,
        newUserId: currentItem.nid
      };

      await axiosInstance.post('/storeExistingUser', requestBody);
    } catch (error) {
      console.error('Error storing existing user:', error.message);
    } finally {
      handleNext();
    }
  };

  const handleNew = async () => {
    try {
      const currentItem = userInfo;
      const requestBody = {
        existingUserId: currentItem.oid,
        newUserId: currentItem.nid
      };
      const response = await axiosInstance.post('/storeNewUser', requestBody);
      console.log('Response from server:', response.data);
    } catch (error) {
      console.error('Error storing new user:', error.message);
    } finally {
      handleNext();
    }
  };

  const handleKeepBoth = async () => {
    try {
      const currentItem = userInfo;
      const requestBody = {
        ids: [currentItem.oid, currentItem.nid]
      };
      const response = await axios.post(
        `${FLASK_API_URL}/update_reconciled`,
        requestBody
      );
      console.log('Response from server:', response.data);
    } catch (error) {
      console.error('Error updating reconciled status:', error.message);
    } finally {
      handleNext();
    }
  };

  if (error) {
    console.log(error);

    // if(!noFacesFound) toast.error("Error Occured")
    // return ;
  }

  const currentData = userInfo;

  console.log('current data');
  console.log(currentData);

  return (
    <Spin size="large" spinning={loading}>
      <PageTitle title="Reconciliation" />
      <div className="journey-card">
        {!data && (
          <div
            className="flex justify-center items-center "
            style={{ flexDirection: 'column' }}
          >
            <div
              className="flex"
              style={{ padding: '8px', marginBottom: '8px' }}
            >
              This page is used to fix duplicate data. Please click the button
              given below to start merging data.
            </div>
            <button
              style={{
                backgroundColor: '#2CD074',
                border: '1px solid #2CD074',
                marginRight: '5px'
              }}
              className="custom-button"
              onClick={fetchSimilarFaces}
            >
              Show Duplicate Data
            </button>
          </div>
        )}

        {currentData && !noFacesFound && (
          <>
            <div style={{ padding: '4px' }}>
              {currentInd} / {currentDataLength}
            </div>
            <Card variant="outlined" style={{ marginBottom: '12px' }}>
              <CardHeader
                title="Old User"
                action={
                  <button
                    style={{
                      backgroundColor: '#2CD074',
                      border: '1px solid #2CD074',
                      marginRight: '5px'
                    }}
                    className="custom-button"
                    onClick={handleOld}
                  >
                    Keep Old User
                  </button>
                }
              />
              <div className="flex">
                <div>
                  {currentData?.oldUserInfo?.imagebase64 && (
                    <CardMedia
                      component="img"
                      image={currentData.oldUserInfo?.imagebase64}
                      alt="Old User Image"
                      style={{
                        height: '100px',
                        width: '100px',
                        padding: '4px 8px'
                      }}
                    />
                  )}
                </div>
                <div>
                  <CardContent>
                    <div>
                      Name: {currentData.oldUserInfo?.f_name}{' '}
                      {currentData.oldUserInfo?.l_name}
                    </div>
                    <div>Contact: {currentData.oldUserInfo?.contact}</div>
                    <div>Aadhar: {currentData.oldUserInfo?.aadhar}</div>
                    <div>
                      Village: {currentData.oldUserInfo?.village.v_name}
                    </div>
                  </CardContent>
                </div>
              </div>
            </Card>

            <Card variant="outlined">
              <CardHeader
                title="New User"
                action={
                  <button
                    style={{
                      backgroundColor: '#2CD074',
                      border: '1px solid #2CD074',
                      marginRight: '5px'
                    }}
                    className="custom-button"
                    onClick={handleNew}
                  >
                    Keep New User
                  </button>
                }
              />
              <div className="flex">
                <div>
                  {currentData?.newUserInfo?.imagebase64 && (
                    <CardMedia
                      component="img"
                      image={currentData.newUserInfo?.imagebase64}
                      alt="New User Image"
                      style={{
                        height: '100px',
                        width: '100px',
                        padding: '4px 8px'
                      }}
                    />
                  )}
                </div>
                <div>
                  <CardContent>
                    <div>
                      Name: {currentData.newUserInfo?.f_name}{' '}
                      {currentData.newUserInfo?.l_name}
                    </div>
                    <div>Contact: {currentData.newUserInfo?.contact}</div>
                    <div>Aadhar: {currentData.newUserInfo?.aadhar}</div>
                    <div>
                      Village: {currentData.newUserInfo?.village.v_name}
                    </div>
                  </CardContent>
                </div>
              </div>
            </Card>

            <div
              className="flex"
              style={{ justifyContent: 'flex-end', paddingTop: '14px' }}
            >
              <button
                style={{
                  backgroundColor: '#3676cc',
                  marginLeft: '14px'
                }}
                className="custom-button"
                onClick={handleKeepBoth}
              >
                Keep Both
              </button>
            </div>
          </>
        )}

        {noFacesFound && (
          // <div className="flex" style={{ padding: "8px", marginBottom: "8px" }}>
          //   No faces found in the database.
          // </div>
          <div
            className="flex justify-center items-center"
            style={{ flexDirection: 'column' }}
          >
            <div
              className="flex"
              style={{ padding: '8px', marginBottom: '8px' }}
            >
              No faces found in the database.
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default Reconciliation;
