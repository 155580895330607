// Callbook.jsx

import React, { useState, useContext } from 'react';
import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from '@mui/material';
import * as XLSX from 'xlsx';
import { callbookDummyData } from '../data/callbookDummyData';
import Notification from './Notification';
import '../Styles/Callbook.css';
import '../Styles/Notification.css'; // Import the new styles
import { ThemeContext } from '../index';
const Callbook = () => {
  const [data, setData] = useState(callbookDummyData);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [notification, setNotification] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      if (file === selectedFile) {
        showNotification('Same file selected. Please choose a different file.');
        return;
      }

      setSelectedFile(file);
      setLoading(true);

      const isValid = isValidFileType(file);
      if (!isValid) {
        showNotification(
          'Invalid file type. Please choose a valid Excel file.'
        );
        setLoading(false);
        return;
      }

      const excelData = await readExcelFile(file);

      localStorage.setItem('callbookData', JSON.stringify(excelData));

      setData(excelData);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const readExcelFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });

          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          const headers = jsonData[0];
          const excelData = jsonData.slice(1).map((row) => {
            return headers.reduce((obj, header, index) => {
              obj[header] = row[index];
              return obj;
            }, {});
          });

          resolve(excelData);
        } catch (error) {
          reject(error);
        }
      };

      reader.readAsBinaryString(file);
    });
  };

  const isValidFileType = (file) => {
    return (
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification(null);
    }, 5000); // Hide notification after 5 seconds
  };

  const closeNotification = () => {
    setNotification(null);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="main">
      <div
        className="uploadButton"
        style={{ backgroundColor: '#2CD074', border: '1px solid #2CD074' }}
      >
        <label
          htmlFor="file-upload"
          style={{ backgroundColor: '#2CD074', border: '1px solid #2CD074' }}
          className="uploadButton"
        >
          Import Data
        </label>
        <input
          type="file"
          accept=".xlsx, .xls"
          id="file-upload"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
      </div>

      {loading ? (
        <CircularProgress style={{ marginTop: '20px' }} />
      ) : (
        <TableContainer component={Paper} className="tableContainer">
          <Table>
            <TableHead>
              {Object.keys(data[0]).map((header) => (
                <TableCell
                  style={{ color: theme.palette.mode === 'dark' && 'black' }}
                  key={header}
                >
                  {header}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {currentRows.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, index) => (
                    <TableCell key={index}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Notification component */}
      <Notification
        className="notification"
        message={notification}
        onClose={closeNotification}
        visible={!!notification}
      />

      <div className="pagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>{' '}
        <span>
          Page <strong>{currentPage}</strong>
        </span>{' '}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastRow >= data.length}
        >
          {'>'}
        </button>{' '}
      </div>
    </div>
  );
};

export default Callbook;
