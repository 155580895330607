import React, { useContext, useState } from 'react';
import AuthContext from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import logo from '../Assets/army-logo-2.png';
import { Constants } from './common/constants';
const { REACT_APP_CORE_URL } = Constants;

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { authenticated, setAuthenticated, setUserType } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const credentials = {
      username: username,
      password: password
    };

    try {
      const response = await fetch(`${REACT_APP_CORE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials),
        credentials: 'include'
      });

      const data = await response.json();

      if (data.success) {
        console.log('Login successful - react');
        let userType = '';

        if (username === 'user') {
          userType = 'user';
        } else if (username === 'admin') {
          userType = 'admin';
        }

        setAuthenticated(true);
        setUserType(userType);
        localStorage.setItem('iccc-authenticated', true);
        localStorage.setItem('iccc-userType', userType);
        navigate('/');
      } else {
        notification.warning({
          message: 'User name or password is wrong!!'
        });
      }
    } catch (error) {
      console.error('Error during login:', error);
      notification.error({
        message: 'An error occurred during login'
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#EFF2FF]">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="flex justify-center mb-2">
          <img src={logo} className="h-[8rem] w-[8rem]" />
        </div>
        <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">
          GMTS - Command Center
        </h2>
        <form onSubmit={handleLogin}>
          <div className="mb-6">
            <label
              htmlFor="username"
              className="block text-gray-800 font-medium mb-1"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500 bg-gray-100 text-gray-800 placeholder-gray-600"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-gray-800 font-medium mb-1"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500 bg-gray-100 text-gray-800 placeholder-gray-600"
              required
            />
          </div>
          <div className="mb-6">
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white py-3 rounded-md hover:bg-indigo-700 transition duration-300"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
