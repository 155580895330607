import { Button, Select } from 'antd';
import React, { useEffect, useState, useContext, useRef } from 'react';
import RelationGraph from 'relation-graph-react';
import { ThemeContext } from '../index';
import userSvg from './user.svg';
import villageSvg from './villageIcon2.svg';
import neo4j from 'neo4j-driver';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { Constants } from './common/constants';
import PageTitle from './common/pageTitle';

const CustomRelationGraph = () => {
  const { theme } = useContext(ThemeContext);
  const [graphData, setGraphData] = useState({
    rootId: '',
    nodes: [{ id: 'root', opacity: 0 }],
    lines: []
  });
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [villageOptions, setVillageOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedVillage, setSelectedVillage] = useState(null);
  const graphRef = useRef(null);

  const fetchWithAuth = useFetchWithAuth();

  useEffect(() => {
    const driver = neo4j.driver(
      Constants.NEO4J_API_URL,
      neo4j.auth.basic('neo4j', Constants.NEO4J_PASS)
    );
    const session = driver.session();

    let query = '';

    if (selectedVillage) {
      // Fetch only residents of the selected village
      query = `
        MATCH (v:Village {village_id: "${selectedVillage}"})<-[:RESIDENT]-(u:USER)
        RETURN u.name AS name, u.user_id AS id`;
    } else {
      // Fetch all users
      query = 'MATCH (u:USER) RETURN u.name AS name, u.user_id AS id';
    }

    session
      .run(query)
      .then((result) => {
        const people = result.records.map((record) => ({
          label: record.get('name'),
          value: record.get('id')
        }));
        setPeopleOptions(people);
      })
      .finally(() => {
        session.close();
      });
  }, [selectedVillage]);

  const getRelationGraph = () => {
    const driver = neo4j.driver(
      Constants.NEO4J_API_URL,
      neo4j.auth.basic('neo4j', Constants.NEO4J_PASS)
    );
    const session = driver.session();

    let query = '';

    if (selectedUser) {
      // Fetch the village where the selected user resides
      query = `
        MATCH (u:USER {user_id: "${selectedUser}"})-[:RESIDENT]->(v:Village)
        RETURN v.name AS name, v.village_id AS id`;
    } else {
      // Fetch all villages if no user is selected
      query = 'MATCH (v:Village) RETURN v.name AS name, v.village_id AS id';
    }

    session
      .run(query)
      .then((result) => {
        const villages = result.records.map((record) => ({
          label: record.get('name'),
          value: record.get('id')
        }));
        setVillageOptions(villages);
      })
      .finally(() => {
        session.close();
      });
  };

  useEffect(getRelationGraph, []);

  const showGraph = () => {
    const graphInstance = graphRef.current?.getInstance();
    if (graphInstance) {
      graphInstance.setJsonData(graphData).then(() => {
        graphInstance.moveToCenter();
        graphInstance.zoomToFit();
      });
    }
  };

  useEffect(() => {
    showGraph();
  }, [graphData]);

  const fetchInitialRelations = (userId) => {
    const driver = neo4j.driver(
      Constants.NEO4J_API_URL,
      neo4j.auth.basic('neo4j', Constants.NEO4J_PASS)
    );
    const session = driver.session();

    const query = `
      MATCH (u:USER {user_id: "${userId}"})-[r]->(m)
      RETURN 
        u.name AS sourceName, 
        u.user_id AS sourceId, 
        m.name AS targetName, 
        CASE 
          WHEN 'Village' IN labels(m) THEN m.village_id 
          ELSE m.user_id 
        END AS targetId, 
        type(r) AS relationshipType, 
        labels(m) AS targetLabels`;

    session.run(query).then(async (result) => {
      const dbLinks = result.records.map((r) => ({
        from: r.get('sourceId'),
        to: r.get('targetId'),
        text: r.get('relationshipType'),
        color: r.get('relationshipType') === 'RESIDENT' ? 'red' : 'green',
        lineShape: 1,
        lineWidth: r.get('relationshipType') === 'RESIDENT' ? 1 : 2
      }));
      session.close();

      const ids = new Set();
      dbLinks.forEach((l) => {
        ids.add(l.from);
        ids.add(l.to);
      });

      console.log(result.records);

      const dbNodes = await Promise.all(
        Array.from(ids).map(async (id) => {
          console.log(id);

          if (id.startsWith('V')) {
            // Handle village node
            return {
              id: id,
              text:
                villageOptions.find((village) => village.value === id)?.label ||
                id,
              html: `
                <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
                  <div style="border-radius: 50%; width: 50px; height: 50px; background-color: #888; display: flex; align-items: center; justify-content: center;">
                    <span style="color: white; font-weight: bold; text-align: center;">${
                      villageOptions.find((village) => village.value === id)
                        ?.label || id
                    }</span>
                  </div>
                </div>
              `
            };
          } else {
            // Handle user node
            const image = await fetchWithAuth(
              `${Constants.REACT_APP_CORE_URL}/getUserImage/${id}`,
              {
                credentials: 'include'
              }
            )
              .then((res) => res.text())
              .catch(() => null); // Handle fetch errors gracefully

            return {
              id: id,
              text:
                peopleOptions.find((person) => person.value === id)?.label ||
                id,
              html: image
                ? `
                  <div style="display: flex; flex-direction:column; height: 100%; width: 100%;">
                    <img src="${image ? image : '/blank-profile-picture.png'}"
                         style=" min-width: 100px; max-width:100px; height: 100px; object-fit: cover; border: 1px solid #000; border-radius: 8%;" 
                         alt="${id}" />
                    <span style="text-align: center;">${
                      peopleOptions.find((person) => person.value === id)
                        ?.label || id
                    }</span>
                  </div>
                `
                : `
                  <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
                    <div style="border-radius: 50%; width: 50px; height: 50px; background-color: #ccc; display: flex; align-items: center; justify-content: center;">
                      <span style="color: black; font-weight: bold; text-align: center;">${id}</span>
                    </div>
                  </div>
                `
            };
          }
        })
      );

      const graphData = {
        rootId: userId,
        nodes: dbNodes,
        lines: dbLinks
      };

      setGraphData(graphData);
    });
  };

  const fetchAdditionalRelations = (userId) => {
    const driver = neo4j.driver(
      Constants.NEO4J_API_URL,
      neo4j.auth.basic('neo4j', Constants.NEO4J_PASS)
    );
    const session = driver.session();

    // Get the existing nodes to avoid fetching relations that are already present
    const existingNodes = graphRef.current
      ?.getInstance()
      .getNodes()
      .map((node) => node.id)
      .filter((id) => !id.startsWith('V'));
    console.log(existingNodes, 'existingNodes');

    const query = `
    MATCH (u:USER {user_id: "${userId}"})-[r]->(m)
    WHERE 
      (m:USER AND NOT m.user_id IN ${JSON.stringify(existingNodes)}) 
      OR NOT m:USER
    RETURN 
      u.name AS sourceName, 
      u.user_id AS sourceId, 
      m.name AS targetName, 
      CASE 
        WHEN 'Village' IN labels(m) THEN m.village_id 
        ELSE m.user_id 
      END AS targetId, 
      type(r) AS relationshipType, 
      labels(m) AS targetLabels
  `;

    session.run(query).then(async (result) => {
      console.log(result.records);

      // Extract the forward links only
      const dbLinks = result.records.map((r) => ({
        from: r.get('sourceId'),
        to: r.get('targetId'),
        text: r.get('relationshipType'),
        color: r.get('relationshipType') === 'RESIDENT' ? 'red' : 'green',
        lineShape: 1,
        lineWidth: r.get('relationshipType') === 'RESIDENT' ? 1 : 2
      }));
      session.close();

      // Collect unique IDs of the nodes from the newly fetched links
      const ids = new Set(existingNodes); // Keep the existing nodes
      dbLinks.forEach((l) => {
        ids.add(l.from);
        ids.add(l.to);
      });

      // Create the new nodes based on the unique IDs
      let newNodes = await Promise.all(
        result.records.map(async (r) => {
          const targetId = r.get('targetId');

          if (targetId.startsWith('V')) {
            // Handle village node
            return {
              id: targetId,
              text:
                villageOptions.find((village) => village.value === targetId)
                  ?.label || targetId,
              html: `<div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
                       <div style="border-radius: 50%; width: 50px; height: 50px; background-color: #888; display: flex; align-items: center; justify-content: center;">
                         <span style="color: white; font-weight: bold; text-align: center;">${
                           villageOptions.find(
                             (village) => village.value === targetId
                           )?.label || targetId
                         }</span>
                       </div>
                     </div>`
            };
          } else {
            // Handle user node
            const image = await fetchWithAuth(
              `${Constants.REACT_APP_CORE_URL}/getUserImage/${targetId}`,
              {
                credentials: 'include'
              }
            )
              .then((res) => res.text())
              .catch(() => null); // Handle fetch errors gracefully

            console.log(r.get('targetName'), 'targetName');

            return {
              id: targetId,
              text: r.get('targetName'),

              html: image
                ? `<div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
                     <img src="${image ? image : '/blank-profile-picture.png'}" 
                          style=" min-width: 100px; max-width:100px; height: 100px; object-fit: cover;  border: 1px solid #000; border-radius: 8%;" 
                          alt="${targetId}" />
                     <span style="margin-left: 8px; text-align: center;">${r.get(
                       'targetName'
                     )}</span>
                   </div>`
                : null
            };
          }
        })
      );

      // Merge new nodes and lines with the existing ones
      setGraphData((prevGraphData) => {
        console.log('prevGraphData', [...newNodes]);

        return {
          ...prevGraphData,
          nodes: [
            ...prevGraphData.nodes,
            ...newNodes.filter(
              (node) => !prevGraphData.nodes.some((n) => n.id === node.id)
            )
          ],
          lines: [
            ...prevGraphData.lines,
            ...dbLinks.filter(
              (link) =>
                !prevGraphData.lines.some(
                  (l) => l.from === link.from && l.to === link.to
                )
            )
          ]
        };
      });
    });
  };

  useEffect(() => {
    if (selectedUser) {
      fetchInitialRelations(selectedUser);
    }
  }, [selectedUser]);

  const handleNodeClick = (nodeId) => {
    fetchAdditionalRelations(nodeId);
  };

  const graphOptions = {
    // You can add your graph configurations here
  };

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <PageTitle title="People Relations Graph" />
      <div
        className="flex flex-col journey-card"
        style={{
          alignItems: 'normal'
        }}
      >
        <div className="flex gap-5 items-center justify-start w-full">
          <div className="flex flex-col justify-start items-start">
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>
              Village
            </label>
            <Select
              placeholder="Select Village"
              options={villageOptions}
              onChange={(value) => setSelectedVillage(value)}
              allowClear
              showSearch
              optionFilterProp="label"
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : ''
              }}
            />
          </div>
          <div className="flex flex-col justify-start items-start">
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>
              Person
            </label>
            <Select
              placeholder="Select Person"
              options={peopleOptions}
              onChange={(value) => setSelectedUser(value)}
              allowClear
              showSearch
              optionFilterProp="label"
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : ''
              }}
            />
          </div>
          <Button
            style={{
              background: '#2CD074',
              color: 'white',
              border: '1px solid #2CD074',
              marginTop: '15px'
            }}
            onClick={() => getRelationGraph()}
          >
            Submit
          </Button>

          <Button
            onClick={() => window.location.reload()}
            style={{
              background: '#dc2626',
              border: 'none',
              color: 'white',
              marginTop: '15px',
              marginLeft: 'auto'
            }}
          >
            Reset
          </Button>
        </div>
        <div style={{ height: 'calc(100vh - 300px)' }}>
          <RelationGraph
            ref={graphRef}
            options={graphOptions}
            onNodeClick={(nodeObject) => handleNodeClick(nodeObject.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomRelationGraph;
