import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Popover, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import LogoutIcon from '@mui/icons-material/Logout';
import html2canvas from 'html2canvas';
import MenuIcon from '@mui/icons-material/Menu';

import ArmyLogo1 from '../Assets/army-logo-1.png';
import ArmyLogo2 from '../Assets/army-logo-2.png';
import ArmyLogo3 from '../Assets/army-logo-3.png';
import AuthContext from '../context/authContext';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../index';
import Switch from '@mui/material/Switch';
import styled from '@emotion/styled';

import PrintIcon from '@mui/icons-material/PrintOutlined';
import { Button, Menu } from '@mui/material';
import { ParentRefContext } from '../context/printContext';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { logoutAndRedirect } from '../utils/Logout';
import { Constants } from './common/constants';
import { useDrawer } from '../context/drawerContext';
const { REACT_APP_CORE_URL } = Constants;
import { ReactComponent as LogoutLogo } from '../images/logout-logo.svg';
const Header = () => {
  const { open, toggleDrawer } = useDrawer();
  const { setAuthenticated, userType } = useContext(AuthContext);

  const navigate = useNavigate();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const parentDivRef = useContext(ParentRefContext);

  const [divRef, setDivRef] = useState();
  const fetchWithAuth = useFetchWithAuth();

  useEffect(() => {
    setDivRef(ParentRefContext);
  }, [ParentRefContext]);

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 63,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#000'
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#4f4f4f' : '#aab4be'
        }
      }
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#f5f5f5'
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
      }
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#4f4f4f' : '#aab4be', // Changed color to shades of gray and black
      borderRadius: 20 / 2
    }
  }));

  const handlePrint = () => {
    html2canvas(document.body).then((canvas) => {
      const dataUrl = canvas.toDataURL();
      const printWindow = window.open('', '_blank');
      console.log(printWindow, '======window');
      printWindow.document.open();
      printWindow.document.write(`<img src="${dataUrl}" style="width:100%"/>`);
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.focus();
        printWindow.print();
        printWindow.close(); // Close the window after printing
      };
    });
  };

  const handleLogout = () => {
    fetchWithAuth(`${REACT_APP_CORE_URL}/logout`, {
      method: 'POST',
      credentials: 'include'
    })
      .then(() => {
        logoutAndRedirect(navigate, setAuthenticated);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(open, 'khul jaa');

  return (
    <>
      <div
        style={{ padding: '0 26px', width: '100%' }}
        className="font-bold z-0"
      >
        <div className="flex justify-between items-center">
          <div className="flex">
            <Button
              variant="text"
              onClick={toggleDrawer}
              style={{
                color: 'cadetblue'
              }}
            >
              <MenuIcon />
            </Button>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                className="flex justify-between items-center"
                style={{
                  border: theme.palette.mode === 'light' && '1px solid #000000',
                  backgroundColor: theme.palette.mode === 'dark' && '#4E4E4D',
                  padding: '8px 24px',
                  borderRadius: '84px'
                }}
              >
                <img src={ArmyLogo3} alt="logo" className="h-[2rem] w-[2rem]" />
                <img
                  src={ArmyLogo1}
                  alt="logo"
                  className="h-[2rem] w-[2rem] ml-5"
                />
              </div>
              {/* <img src={Logo} alt="logo" className="h-[2rem] w-[2rem] ml-2" /> */}
              <span
                className="text-[1.5rem] font-bold uppercase ml-3 text-black-900"
                style={{
                  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000'
                }}
              >
                Grid Monitoring & Tracking System (GMTS) - Command Center
              </span>
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #4E4E4D',
                borderRadius: '100px',
                padding: '8px',
                margin: '0px 2px 0px 4px'
              }}
            >
              <button
                type="button"
                onClick={handlePrint}
                className="rounded"
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  marginRight: '8px',
                  marginLeft: '2px'
                }}
              >
                Print
              </button>
              <PrintIcon
                fontSize="medium"
                style={{
                  color: 'black'
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #4E4E4D',
                borderRadius: '100px',
                padding: '8px',
                margin: '0px 2px 0px 4px'
              }}
            >
              <button
                type="button"
                onClick={handleLogout}
                className="rounded"
                style={{
                  color: theme.palette.mode === 'dark' ? 'white' : '#191818',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  marginRight: '8px' // Optional: Add some space between button text and icon
                }}
              >
                Logout
              </button>
              <LogoutLogo
                style={{
                  fill: theme.palette.mode === 'dark' ? 'white' : '#191818'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
