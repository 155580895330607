import React, { useEffect, useState } from 'react';
import { Spin, notification } from 'antd';
import { useAxiosInstance } from './Interceptor';
import { Link, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel
} from '@mui/material';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { toast } from 'react-toastify';
import { Constants } from './common/constants';
import clsx from '../utils/clsx';
import PageTitle from './common/pageTitle';
const { REACT_APP_CORE_URL } = Constants;

const EditResident = () => {
  const { userId } = useParams();

  const [userInfo, setUserInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const axios = useAxiosInstance();

  useEffect(() => {
    setIsLoading(true);
    setNotFound(false);
    axios
      .get(`/getUserInfoById/${userId}`)
      .then((res) => {
        setUserInfo(res.data.result);
        if (!res.data.result) setNotFound(true);
        return axios.post('/getRelationship', {
          user_id: userId,
          page: 1,
          limit: 100
        });
      })
      .then((res) => {
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          relations: res.data.result
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  const personalDetailsData = [
    { label: 'First Name', value: userInfo?.f_name },
    { label: 'Last Name', value: userInfo?.l_name },
    { label: 'Alias', alue: userInfo?.alias_name },
    { label: 'Contact', value: userInfo?.contact },
    { label: 'Age', alue: userInfo?.age },
    { label: 'Religion', alue: userInfo?.religion },
    { label: 'Aadhaar', value: userInfo?.aadhar },
    {
      label: 'DOB',
      value: new Date(userInfo?.dob).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  ];
  const villageDetails = [
    { label: 'Village', value: userInfo?.village?.v_name },
    { label: 'Last Name', value: userInfo?.address }
  ];
  const socialMediaDetails = [
    { label: 'Email Id', value: userInfo?.mail_id },
    { label: 'Last Name', value: userInfo?.social_media }
  ];
  const educationDetails = [
    { label: 'Qualification', value: userInfo?.qualification },
    { label: 'School or University', value: userInfo?.school },
    { label: 'Education Info', value: userInfo?.education_info }
  ];
  const professionalDetails = [
    { label: 'Profession', value: userInfo?.profession }
  ];

  const handleSave = async (event) => {
    setIsLoading(true);
    axios
      .put(`/updateUserInfo/${userId}`, { category: userInfo?.category })
      .then((res) =>
        api['success']({
          message: 'Updated Successfully',
          description: 'User category Updated'
        })
      )
      .catch((err) =>
        api['error']({
          message: 'Error Updating'
        })
      )
      .finally(() => setIsLoading(false));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (notFound) {
    return (
      <h1 className="w-full p-10 text-center font-medium text-xl">
        User not found
      </h1>
    );
  }

  return (
    <Spin size="large" spinning={isLoading}>
      <PageTitle title={`${userInfo?.f_name} ${userInfo?.l_name} Details`} />
      {console.log(userInfo, 'user info')}
      <h1 className="text-xl font-bold py-4">Edit Resident</h1>
      <Divider />

      <div className="flex w-full py-2 gap-8">
        <div className="my-2 grid grid-cols-4 gap-10 h-fit">
          <div className="flex flex-col">
            <DetailsSection
              title={'Personal Details'}
              details={personalDetailsData}
              className={'self-start'}
            />
          </div>

          <div className="flex flex-col justify-between items-stretch">
            <DetailsSection
              title={'Village Details'}
              details={villageDetails}
              className="flex-1"
            />
            <DetailsSection
              title={'Education Details'}
              details={educationDetails}
              className="flex-1"
            />
          </div>

          <div className="flex flex-col justify-between items-stretch">
            <DetailsSection
              title={'Social Media Details'}
              details={socialMediaDetails}
              className="flex-1"
            />
            <DetailsSection
              title={'Professional Details'}
              details={professionalDetails}
              className="flex-1"
            />
          </div>

          <div className="relative flex flex-col justify-between items-start">
            <div className="w-full">
              <select
                className={`w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                id="grid-state"
                onChange={(e) =>
                  setUserInfo((prev) => ({ ...prev, category: e.target.value }))
                }
                value={userInfo?.category}
              >
                <option value={''}>Please select category of person</option>
                <option value="Villager">Villager</option>
                <option value="Govt official">Govt official</option>
                <option value="Tourist">Tourist</option>
                <option value="Defence Person">Defence Person</option>
                <option value="Ex-Defence Person">Ex-Defence Person</option>
                <option value="OGW (Over Ground Worker)">
                  OGW (Over Ground Worker)
                </option>
                <option value="SM">SM</option>
                <option value="Terrorist">Terrorist</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mt-auto self-end flex gap-8">
              <Button
                variant="contained"
                className="bg-green-500"
                style={{ backgroundColor: 'rgb(34 197 94)' }}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={handleClickOpen}
              >
                DELETE
              </Button>
              <DialogBox
                handleClose={handleClose}
                open={open}
                userId={userId}
              />
            </div>
          </div>
        </div>

        <div className="w-2/5 flex-col justify-between items-end">
          <img src={userInfo?.imagebase64} alt="" />
        </div>
      </div>
      {userInfo && userInfo.relations?.length > 0 && (
        <>
          <p className="text-xl font-semibold mt-8">Relations</p>
          <div className="mt-8 flex gap-8 overflow-x-scroll">
            {userInfo.relations?.map((person, idx) => (
              <DetailsCard
                key={idx}
                fName={person?.relative?.f_name}
                lName={person?.relative?.l_name}
                label={person?.relation}
                photo={person?.relative?.imagebase64}
                aadhar={person?.relative?.aadhar}
                contact={person?.relative?.contact}
                village={person?.village?.v_name}
                Pincode={person.relative.pincode}
                city={person?.relative?.city}
                state={person?.relative?.state}
                userId={person?.relative?.user_id}
              />
            ))}
          </div>
        </>
      )}
      {userInfo &&
        userInfo.userRelationships?.relationship_json?.dependants?.length >
          0 && (
          <>
            <p className="text-xl font-semibold mt-8">Dependants</p>
            <div className="mt-8 overflow-x-scroll">
              {userInfo.userRelationships?.relationship_json?.dependants.map(
                (person, idx) => (
                  <DetailsCard
                    key={idx}
                    fName={person.fName}
                    lName={person.lName}
                    label={person.relative}
                    photo={person.photo}
                    aadhar={person.aadhar}
                    contact={person.contact}
                    village={person.village}
                    Pincode={person.pincode}
                    city={person.city}
                    state={person.state}
                  />
                )
              )}
            </div>
          </>
        )}
      {userInfo &&
        userInfo.userRelationships?.relationship_json?.Nondependants?.length >
          0 && (
          <>
            <p className="text-xl font-semibold mt-8">Non Dependants</p>
            <div className="mt-8 overflow-x-scroll">
              {userInfo.userRelationships?.relationship_json?.Nondependants?.map(
                (person, idx) => (
                  <DetailsCard
                    key={idx}
                    fName={person.fName}
                    lName={person.lName}
                    label={person.relative}
                    photo={person.photo}
                    aadhar={person.aadhar}
                    contact={person.contact}
                    village={person.village}
                    Pincode={person.pincode}
                    city={person.city}
                    state={person.state}
                  />
                )
              )}
            </div>
          </>
        )}
    </Spin>
  );
};

export default EditResident;

const DetailsSection = ({ title, details, className }) => (
  <div className={`space-y-5 ${className}`}>
    <p className="font-semibold">{title}</p>
    {details.map((item, index) => (
      <span key={index} className="flex gap-2">
        <p>{item.label}: </p>
        <p>{item.value}</p>
      </span>
    ))}
  </div>
);

const DetailsCard = ({
  fName,
  lName,
  label,
  photo,
  village,
  contact,
  aadhar,
  city,
  state,
  Pincode,
  userId
}) => {
  const details = [
    { label: 'First Name', value: fName },
    { label: 'Last Name', value: lName },
    { label: 'Contact', value: contact },
    { label: 'Aadhar', value: aadhar },
    { label: 'Village', value: village },
    { label: 'City', value: city },
    { label: 'State', value: state },
    { label: 'Pincode', value: Pincode }
  ];

  return (
    <Link to={`/edit-resident-details/${userId}`} target="_blank">
      <Card sx={{ maxWidth: 345 }}>
        {photo && (
          <CardMedia
            component="img"
            height="140"
            width="140"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
              height: '200px',
              width: '200px'
            }}
            image={photo}
            alt={`${fName} ${lName}`}
          />
        )}
        <CardContent
          className="capitalize"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            height: 'fit-content',
            padding: '10px'
          }}
        >
          <p className="text-xl font-semibold tracking-wide">{label}</p>
          {details.map(
            (detail, index) =>
              detail.value && (
                <p key={index}>
                  <span className="font-semibold">{detail.label}</span>:{' '}
                  {detail.value}
                </p>
              )
          )}
        </CardContent>
      </Card>
    </Link>
  );
};

const DialogBox = ({ open, handleClose, userId }) => {
  const [reason, setReason] = useState();
  const fetchWithAuth = useFetchWithAuth();
  const deleteUser = () => {
    if (!reason) return toast.error('Reason cannot be empty');
    fetchWithAuth(`${REACT_APP_CORE_URL}/deleteUserInfo/${userId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ reason: reason })
    })
      .then((res) => {
        if (!res.ok) throw new Error('Bad Request');
        toast.success('DELETED USER');
        handleClose();
        setTimeout(() => {
          window.close();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        toast.error('SOMETHING WENT WRONG');
      });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        zIndex: 500
      }}
    >
      <DialogTitle id="alert-dialog-title">Delete User?</DialogTitle>
      <DialogContent>
        <InputLabel>Reason</InputLabel>
        <Input
          type="text"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outline" className="text-black">
          Cancel
        </Button>
        <Button onClick={deleteUser} autoFocus color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
