import React, { useState, useMemo, useEffect, useContext } from 'react';
import { ThemeContext } from '../index';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  InputLabel
} from '@mui/material';
import { Select, Spin, DatePicker } from 'antd';
import { useNavigate } from 'react-router';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import dayjs from 'dayjs';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Constants } from './common/constants';
import { toast } from 'react-toastify';
import useFetchWithAuth from '../utils/fetchWithAuth';
import { status_options, type_options } from './anomaly-options';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { ReactComponent as InfoIcon } from '../Assets/info-icon.svg';
import PageTitle from './common/pageTitle';
const { REACT_APP_CORE_URL, FLASK_API_URL } = Constants;

const PeopleManagement = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [anomalyData, setAnomalyData] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState('10');
  const [totalRecords, setTotalRecords] = useState();
  const [anomalyDataCount, setAnomalyDataCount] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchWithAuth = useFetchWithAuth();
  const { RangePicker } = DatePicker;
  const [lastAnomalyRefreshTime, setLastAnomalyRefreshTime] = useState(null);
  useEffect(() => {
    const savedLastAnomalyRefreshTime = Cookies.get('AnomalyRefreshTime');
    if (savedLastAnomalyRefreshTime) {
      setLastAnomalyRefreshTime(new Date(savedLastAnomalyRefreshTime));
    }
  }, []);
  const dateHandler = (date) => {
    if (date) {
      const fromDate = moment
        .tz(date[0].toDate(), 'Asia/Kolkata')
        .add(5.5, 'hours')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const toDate = moment
        .tz(date[1].toDate(), 'Asia/Kolkata')
        .add(1, 'day')
        .add(5.5, 'hours')
        .add(-1, 'minutes')
        .add(59, 'seconds')
        .add(999, 'milliseconds')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

      setFilters({
        ...filters,
        startDate: fromDate,
        endDate: toDate
      });
    } else {
      setFilters({
        ...filters,
        startDate: undefined,
        endDate: undefined
      });
    }
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'user.imagebase64',
        header: 'Image',
        size: 50,
        accessorFn: (row) => {
          if (row?.user?.imagebase64) {
            const t = arrayBufferToBase64(row.user.imagebase64.data);
            const base64Image = `data:image/jpeg;base64,${t}`;
            return (
              <img
                className="w-[3rem] h-[3rem] object-cover"
                src={base64Image}
              />
            );
          } else {
            return (
              <div className="w-[3rem] h-[3rem] ring-[1px] ring-gray-400 "></div>
            );
          }
        }
      },
      {
        accessorKey: 'user.f_name',
        header: 'Name',
        size: 100,

        accessorFn: (row) => {
          return (
            <Link
              to={
                row.user?.user_id
                  ? `/edit-resident-details/${row.user.user_id}`
                  : '#'
              }
              className="underline underline-offset-1"
            >
              {row.user?.f_name ? row.user.f_name : 'N/A'}
            </Link>
          );
        }
      },
      {
        accessorKey: 'anomalyType',
        header: 'Anomaly Type',
        size: 150
      },
      {
        accessorKey: 'description.shortDescription',
        header: 'Description',
        size: 150
      },
      {
        accessorKey: 'status',
        header: 'Status Level',
        size: 150
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100,
        accessorFn: (row) => (
          <div className="cursor-pointer flex items-center justify-center">
            <Link to={`/anomaly/${row.id}`}>
              <ModeEditIcon />
            </Link>
          </div>
        )
      }
    ],
    []
  );

  const getAnomalyCount = () => {
    fetchWithAuth(`${REACT_APP_CORE_URL}/anomaly-stats/severity`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setAnomalyDataCount(data);
      });
  };

  useEffect(() => {
    getAnomalyData();
    getAnomalyCount();
  }, [currentPage, rowsPerPage]); // eslint-disable-line

  const getAnomalyData = () => {
    setLoading(true);
    const url = new URL(`${REACT_APP_CORE_URL}/anomalies`);
    if (currentPage) url.searchParams.append('page', currentPage);
    if (rowsPerPage) url.searchParams.append('limit', rowsPerPage);
    if (filters.startDate)
      url.searchParams.append('startDate', filters.startDate);
    if (filters.endDate) url.searchParams.append('endDate', filters.endDate);
    if (filters.status) url.searchParams.append('status', filters.status);
    if (filters.type) url.searchParams.append('type', filters.type);

    fetchWithAuth(url.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setAnomalyData(data);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const analyzeNewAnomaly = () => {
    setLoading(true);
    const url = new URL(`${FLASK_API_URL}/anomaly`);
    // const url = new URL(`http://localhost:5000/anomaly`);
    fetchWithAuth(url.toString(), {
      method: 'POST',
      // credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ phoneNumber: '917723551678' })
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success('Analyze successful');
        const currentTime = new Date();
        setLastAnomalyRefreshTime(currentTime);
        Cookies.set('AnomalyRefreshTime', currentTime.toISOString(), {
          expires: 365
        });
        console.log(
          `Analysis completed successfully at ${lastAnomalyRefreshTime}`
        );
        getAnomalyData();
      })
      .catch((err) => {
        console.log(err);
        toast.error('Analysis failed');
        setLoading(false);
      });
  };

  const table = useMaterialReactTable({
    columns: columns,
    initialState: {
      density: 'compact',
      columnVisibility: {
        aadhar: false,
        // user_id: false,
        city: false,
        country: false,
        wanted: false
      }
    },
    enableHiding: false,
    enableDensityToggle: false,
    data: anomalyData,
    enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => navigate(`/anomaly/${row.original.id}`),
      style: { cursor: 'pointer' }
    }),
    renderTopToolbarCustomActions: () => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          marginLeft: '1rem'
        }}
      >
        <div>
          <label style={{ fontSize: '12px', marginLeft: '5px' }}>Status</label>{' '}
          <Select
            placeholder="Select Status"
            options={status_options}
            style={{
              background: theme.palette.mode === 'dark' ? '#374151' : '',
              color: theme.palette.mode === 'dark' && '#ffffff'
            }}
            allowClear
            onChange={(e) => {
              setFilters({ ...filters, status: e });
            }}
            showSearch
            className="w-[20rem] rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
            maxTagCount={'responsive'}
          />
        </div>
        <div>
          <label style={{ fontSize: '12px', marginLeft: '5px' }}>Type</label>{' '}
          <Select
            placeholder="Select Type"
            options={type_options}
            onChange={(e) => {
              setFilters({ ...filters, type: e });
            }}
            allowClear
            showSearch
            className="rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
            style={{
              background: theme.palette.mode === 'dark' ? '#374151' : '',
              color: theme.palette.mode === 'dark' && '#ffffff'
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ fontSize: '12px', marginLeft: '5px' }}>
            Date Range
          </label>{' '}
          <RangePicker
            className="dark:rounded-full rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={
              filters.startDate
                ? [
                    dayjs(
                      moment(new Date(filters?.startDate)).format('YYYY-MM-DD')
                    ),
                    dayjs(
                      moment(new Date(filters?.endDate)).format('YYYY-MM-DD')
                    )
                  ]
                : []
            }
            format={'DD/MM/YYYY'}
            onChange={(value) => dateHandler(value)}
          />
        </div>
        <Button
          type="primary"
          style={{ marginTop: '20px', backgroundColor: '#2CD074' }}
          onClick={getAnomalyData}
        >
          Submit
        </Button>
        <div className="flex gap-5 mt-4">
          {Object.keys(anomalyDataCount).map((key) => (
            <Chip
              style={{
                fontSize: '20px'
              }}
              label={
                <p className="">
                  <span className="text-xl font-semibold w-full rounded-full text-center bg-slate-300 py-[0.5px] px-1">
                    {anomalyDataCount[key]}{' '}
                  </span>
                  {key}
                </p>
              }
              variant="filled"
              key={key}
            />
          ))}
        </div>
      </div>
    )
    // // getRowId: (row) => row.original, // Unique ID for each row
    // onRowClick: () => {
    //   // navigate(`/anomaly/${row.original.id}`);
    //   // console.log(`row id: ${row.original.id}` )
    //   console.log('row clicked: ')
    // }
  });

  const handleNextPage = () => {
    if (currentPage === Math.ceil(totalRecords / rowsPerPage)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage === 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <PageTitle title="Detected Anomalies" />
      <div className="w-full mb-5">
        <div className="flex justify-start -gap-1">
          <label
            style={{
              cursor: 'pointer',
              backgroundColor: '#2CD074',
              border: '1px solid #2CD074'
            }}
            className="custom-button"
            onClick={analyzeNewAnomaly}
          >
            Refresh Anomaly
          </label>
          <InfoIcon
            className=" h-7 cursor-pointer bold"
            onClick={() =>
              window.open(
                `${process.env.PUBLIC_URL}/Anomalies_Description.pdf`,
                '_blank'
              )
            }
          />
        </div>

        {lastAnomalyRefreshTime && (
          <div
            className="flex justify-start ml-1"
            style={{ lineHeight: '1.2 !important' }}
          >
            Last Imported : &nbsp;
            {format(lastAnomalyRefreshTime, 'dd MMMM yyyy, h.mm a')}
          </div>
        )}

        {/* style={{ lineHeight: '1.2 !important'}} */}
      </div>
      <div id="peopleManagementTable">
        <MaterialReactTable table={table} />
        <div className="mt-3 mb-5 flex justify-between">
          <div className="flex items-center rows-per-page">
            <span>Rows Per Page:</span>
            <Select
              className="mx-2 w-[3.8rem] h-[3rem]"
              options={[
                {
                  value: '5',
                  label: '5'
                },
                {
                  value: '10',
                  label: '10'
                },
                {
                  value: '20',
                  label: '20'
                }
              ]}
              onChange={(e) => {
                setRowsPerPage(e);
                setCurrentPage(1);
              }}
              style={{ width: '60px' }}
              value={rowsPerPage}
            />
          </div>
          <div className="flex items-center ">
            <ArrowBackIosIcon onClick={handlePrevPage} className="w-4" />
            <div>{currentPage}</div>
            <ArrowForwardIosIcon onClick={handleNextPage} className="ml-2" />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PeopleManagement;
