import React, { Fragment, useEffect, useState, useContext } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  // Polyline,
  Tooltip
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.offline';
import 'leaflet/dist/leaflet.css';
import LocationIcon from '../Assets/loactionIcon_red.webp';
import moment from 'moment';
import { Breadcrumb, Button, DatePicker, Select, Spin } from 'antd';
import { useAxiosInstance } from './Interceptor';
import dayjs from 'dayjs';
import { ThemeContext } from '../index';
const MapComponent = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    q: { village_id: [], user_id: [] },
    from_date: moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(-30, 'days')
      .add(-18.5, 'hours')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    to_date: moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(1, 'day')
      .add(5.5, 'hours')
      .add(-1, 'minutes')
      .add(59, 'seconds')
      .add(999, 'milliseconds')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  });
  const [villageList, setVillageList] = useState([]);
  const [village, setVillage] = useState();
  const [peopleList, setPeopleList] = useState([]);
  const [people, setPeople] = useState();
  const [output, setOutput] = useState();
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    fetchVillage();
  }, []);

  useEffect(() => {
    if (state?.q?.village_id) {
      fetchUser(state?.q?.village_id);
    }
  }, [state?.q?.village_id]);

  const fetchUser = async (id) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/getUserNames`, {
        village_id: id
      });
      let tempValue = [];
      res?.data?.result?.forEach((data) => {
        let obj = { label: data?.user_name, value: data?.user_id };
        tempValue.push(obj);
      });
      setPeopleList(tempValue);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (villageList?.length > 0) {
      let tempValue = ['V1000', 'V4433', 'V7735'];
      const allVillageIDs = villageList.filter((village) =>
        tempValue.includes(village.value)
      );
      setVillage(allVillageIDs);
      let data = { ...state, q: { village_id: tempValue } };
      // setState({
      //   ...state,
      //   q: { ...state.q, village_id: tempValue },
      // });
      fetchData(data);
    }
  }, [villageList]); // eslint-disable-line

  useEffect(() => {
    if (peopleList?.length > 0) {
      const allVillageIDs = peopleList.map((village) => village.value);
      setPeople(allVillageIDs);
      setState({
        ...state,
        q: { ...state.q, user_id: allVillageIDs }
      });
    }
  }, [peopleList]); // eslint-disable-line

  const fetchVillage = async () => {
    try {
      const res = await axiosInstance.get(`/getVillageDetails`);
      let tempValue = [];
      res?.data?.result?.forEach((data) => {
        let obj = { label: data?.v_name, value: data?.village_id };
        tempValue.push(obj);
      });
      // setState
      setVillageList(tempValue);
      const tempVillageIds = ['V1000', 'V4433', 'V7735'];
      setState({
        ...state,
        q: {
          ...state.q,
          village_id: tempValue
            .filter((elem) => tempVillageIds.includes(elem.value))
            .map((elem) => elem?.value)
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async (formData) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/getMapTransaction`, formData);
      setOutput(res?.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const customIcon = new L.Icon({
    iconUrl: LocationIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const dateHandler = (date) => {
    if (date) {
      const fromDate = moment
        .tz(date[0].toDate(), 'Asia/Kolkata')
        .add(5.5, 'hours')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const toDate = moment
        .tz(date[1].toDate(), 'Asia/Kolkata')
        .add(1, 'day')
        .add(5.5, 'hours')
        .add(-1, 'minutes')
        .add(59, 'seconds')
        .add(999, 'milliseconds')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

      setState({
        ...state,
        from_date: fromDate,
        to_date: toDate
      });
    }
  };

  const submitHandler = async () => {
    try {
      fetchData(state);
    } catch (e) {
      console.log(e);
    }
  };

  const peopleOnChange = (selectedValues) => {
    if (selectedValues?.includes('all')) {
      if (state?.q?.user_id?.length === peopleList.length) {
        setPeople(selectedValues);
        setState({
          ...state,
          q: { ...state.q, user_id: [] }
        });
      } else {
        setPeople(selectedValues);
        setState({
          ...state,
          q: { ...state.q, user_id: peopleList.map((elem) => elem.value) }
        });
      }
    } else {
      setPeople(selectedValues);
      setState({
        ...state,
        q: { ...state.q, user_id: selectedValues }
      });
    }
  };

  const onChange = (selectedValues) => {
    if (selectedValues?.includes('all')) {
      if (state?.q?.village_id?.length === villageList.length) {
        setVillage(selectedValues);
        setState({
          ...state,
          q: { ...state.q, village_id: [] }
        });
      } else {
        setVillage(selectedValues);
        setState({
          ...state,
          q: { ...state.q, village_id: villageList.map((elem) => elem.value) }
        });
      }
    } else {
      setVillage(selectedValues);
      setState({
        ...state,
        q: { ...state.q, village_id: selectedValues }
      });
    }
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div
          style={{
            padding: '10px',
            background: theme.palette.mode === 'dark' ? '#374151' : '#e6e6e6',
            color: theme.palette.mode === 'dark' && '#ffffff',
            border:
              theme.palette.mode === 'dark'
                ? '1px solid #4b5563'
                : '1px solid transparent',
            borderRadius: '8px'
          }}
        >
          {' '}
          <Breadcrumb
            items={[
              {
                title: 'Home'
              }
            ]}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            margin: '15px 0px 15px 0px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>Span</label>
            <RangePicker
              className="dark rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              defaultValue={[
                dayjs(moment(new Date()).add(-1, 'month').format('YYYY-MM-DD')),
                dayjs(moment(new Date()).format('YYYY-MM-DD'))
              ]}
              format={'DD/MM/YYYY'}
              onChange={(value) => dateHandler(value)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
              // border:
            }}
          >
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>
              Village visited
            </label>
            <Select
              maxTagCount={3}
              mode="multiple"
              className="w-[20rem] rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              allowClear
              showSearch
              value={state?.q?.village_id}
              placeholder="Select a village"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: 'all',
                  label: 'Select All'
                },
                ...villageList
              ]}
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : '',
                color: theme.palette.mode === 'dark' && '#ffffff',
                border:
                  theme.palette.mode === 'dark'
                    ? '1px solid #4b5563'
                    : '1px solid #d9d9d9'
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>
              People
            </label>{' '}
            <Select
              maxTagCount={'responsive'}
              className="w-[20rem] rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
              allowClear
              showSearch
              mode="multiple"
              value={state?.q?.user_id}
              placeholder="Select a People"
              onChange={peopleOnChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: 'all',
                  label: 'Select All'
                },
                ...peopleList
              ]}
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : '',
                color: theme.palette.mode === 'dark' && '#ffffff',
                border:
                  theme.palette.mode === 'dark'
                    ? '1px solid #4b5563'
                    : '1px solid #d9d9d9'
              }}
            />
          </div>
          <Button
            style={{ marginTop: '20px', backgroundColor: '#2CD074' }}
            type="primary"
            onClick={submitHandler}
          >
            Submit
          </Button>
        </div>
        <MapContainer
          center={
            output?.result?.length > 0
              ? output?.result?.[0]?.positions
              : [33.8716, 74.8946]
          }
          zoom={9}
          style={{ height: '90vh', width: '100%' }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {output?.result?.map((transaction, index) => (
            <Marker
              key={index}
              position={transaction.positions}
              icon={customIcon}
            >
              <Tooltip direction="auto" offset={[0, 20]} opacity={1} permanent>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {' '}
                  {transaction?.village_name}{' '}
                </div>
                <div
                  style={{
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    display: 'flex',
                    gap: '10px'
                  }}
                >
                  <div className="entry">{transaction?.count?.entry}</div>|
                  <div className="exit">{transaction?.count?.exit}</div>
                </div>
              </Tooltip>
              <Popup>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
                      Entry{' '}
                    </p>
                    <div className="entry">{transaction?.count?.entry}</div>
                  </div>
                  <div style={{ maxHeight: '7rem', overflow: 'auto' }}>
                    {transaction?.entry?.map((dt, index) => (
                      <div
                        key={index}
                        id={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div>{dt?.name}</div> - <div>{dt?.entryCount}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div style={{ maxHeight: '7rem', overflow: 'auto' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Exit</p>
                    <div className="exit">{transaction?.count?.exit}</div>
                  </div>
                  {transaction?.exit?.map((dt, index) => (
                    <div
                      key={index}
                      id={index}
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>{dt?.name}</div> - <di>{dt?.exitCount}</di>
                    </div>
                  ))}
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </Spin>
    </Fragment>
  );
};

export default MapComponent;
