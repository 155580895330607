const REACT_APP_CORE_URL = 'https://api.cc.seeker.zendot.in';
const FLASK_API_URL = 'https://flask.seeker.zendot.in';
const NEO4J_API_URL = 'bolt+s://neo4j.cc.seeker.zendot.in:7687';  
const NEO4J_PASS = 'password';
export const Constants = {
  REACT_APP_CORE_URL,
  FLASK_API_URL,
  NEO4J_API_URL,
  NEO4J_PASS
};

export const reportActions = {
  new_users: '/people',
  entry_exit_transactions: '/logs',
  anomaly: '/anomaly'
};