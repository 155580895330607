import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/authContext';
import { logoutAndRedirect } from './Logout';

const useFetchWithAuth = () => {
  const { setAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchWithAuth = async (url, options = {}) => {
    try {
      const response = await fetch(url, options);

      if (response.status === 401) {
        logoutAndRedirect(navigate, setAuthenticated);
        return Promise.reject('Unauthorized');
      }

      return response;
    } catch (error) {
      console.error('Fetch error:', error);
      logoutAndRedirect(navigate, setAuthenticated);
      return Promise.reject(error);
    }
  };

  return fetchWithAuth;
};

export default useFetchWithAuth;
