import React, { useEffect, useContext, useState } from 'react';
import Router from './Components/Router';
import AuthContext from './context/authContext';
import { ThemeContext } from './index';
import { ParentRefContext, ParentRefProvider } from './context/printContext';
import { ToastContainer } from 'react-toastify';
import { DrawerProvider } from './context/drawerContext';
const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userType, setUserType] = useState('');
  const [loading, setLoading] = useState(true);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const parentRef = useContext(ParentRefContext);
  useEffect(() => {
    const auth = localStorage.getItem('iccc-authenticated');
    const type = localStorage.getItem('iccc-userType');

    setAuthenticated(auth);
    setUserType(type);
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="h-[100vh] w-[100vw] flex justify-center items-center">
        Loading...
      </div>
    );
  }

  return (
    <ParentRefProvider>
      <div
        className={theme.palette.mode === 'light' ? 'App' : 'App dark'}
        ref={parentRef}
      >
        <AuthContext.Provider
          value={{ authenticated, setAuthenticated, userType, setUserType }}
        >
          <DrawerProvider>
            <React.Fragment>
              <Router />
              <ToastContainer />
            </React.Fragment>
          </DrawerProvider>
        </AuthContext.Provider>
      </div>
    </ParentRefProvider>
  );
};

export default App;
