const ViewImageModal = ({ image, imageClassName, node }) => {
  console.log('node', node);

  return (
    <div>
      <img className={imageClassName} src={image} alt={image} />
    </div>
  );
};

export default ViewImageModal;
