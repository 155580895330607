import { useEffect, useMemo, useState, useContext } from 'react';
import { ThemeContext } from '../index';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import { Spin, notification } from 'antd';
import { Link } from 'react-router-dom';
import DeleteConfirmModal from './DeleteConfirmModal';
import { Constants } from './common/constants';
import useFetchWithAuth from '../utils/fetchWithAuth';
import PageTitle from './common/pageTitle';
const { REACT_APP_CORE_URL } = Constants;

const Villages = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [villages, setVillages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState();
  const [loading, setLoading] = useState(false);
  const fetchWithAuth = useFetchWithAuth();

  useEffect(() => {
    getVillages();
  }, []);

  const columns = useMemo(() => [
    {
      accessorKey: 'village_id', //access nested data with dot notation
      header: 'Id',
      size: 150
    },
    {
      accessorKey: 'v_name', //access nested data with dot notation
      header: 'Name',
      size: 150
    },
    {
      accessorKey: 'v_code', //access nested data with dot notation
      header: 'Village Code',
      size: 150
    },

    {
      accessorKey: 'v_lat', //access nested data with dot notation
      header: 'Latitude',
      size: 150
    },
    {
      accessorKey: 'v_long', //access nested data with dot notation
      header: 'Longitude',
      size: 150
    },
    {
      accessorKey: 'v_district', //access nested data with dot notation
      header: 'District',
      size: 150
    },
    {
      accessorKey: 'v_state', //access nested data with dot notation
      header: 'State',
      size: 150
    },
    {
      accessorKey: 'v_pincode', //access nested data with dot notation
      header: 'Pincode',
      size: 150
    },
    {
      accessorKey: 'actions', //access nested data with dot notation
      header: 'Action',
      size: 150,
      accessorFn: (row) => (
        <div className="flex">
          <Link
            to={'/edit-village/' + row.village_id}
            state={{ villageData: row }}
          >
            {/* <button> */}
            <EditIcon className="text-gray-800 dark:text-white" />
            {/* </button> */}
          </Link>
          <div className="cursor-pointer">
            <DeleteConfirmModal
              handleDelete={() => deleteVillage(row.village_id)}
            />
          </div>
        </div>
      )
    }
  ]);

  const table = useMaterialReactTable({
    columns,
    data: villages,
    initialState: {
      density: 'compact',
      columnVisibility: {
        v_pincode: false
      }
    },
    enableHiding: false,
    enableDensityToggle: false,
    // enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false

    // renderTopToolbarCustomActions: () => (
    //   <div className="flex items-end">
    //     <div className="flex flex-col item">
    //       <div className="text-left text-[1rem] text-gray-500">
    //         Select Date{" "}
    //       </div>
    //       <RangePicker
    //         format={"DD/MM/YYYY"}
    //         defaultValue={[
    //           dayjs(moment(new Date()).add(-1, "month").format("YYYY-MM-DD")),
    //           dayjs(moment(new Date()).format("YYYY-MM-DD")),
    //         ]}
    //         onChange={handleDateChange}
    //       />
    //     </div>{" "}
    //     <button
    //       className=" px-3 py-1 rounded ml-3 h-[2rem] bg-green-500 text-white  "
    //       onClick={getTransactions}
    //     >
    //       Submit
    //     </button>
    //   </div>
    // ),
  });

  const getVillages = () => {
    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/getVillageDetails`, {
      credentials: 'include'
    })
      .then(async (res) => {
        const data = await res.json();
        const vills = data.result.filter((elem) => elem.village_id !== 'V5042');
        setVillages(vills);
        setTotalRecords(data.totalRecords);
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  };

  const deleteVillage = (id) => {
    if (id == 'V1000' || id == 'V7735' || id == 'V4433') {
      notification.warning({
        message: 'You are not authorised to delete this Village!'
      });
      return;
    }

    setLoading(true);
    fetchWithAuth(`${REACT_APP_CORE_URL}/deleteVillageDetails/${id}`, {
      method: 'DELETE',
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode == 204) {
          notification.success({ message: 'Village Deleted!!' });
        }
        getVillages();
        // setLoading(false);
      })
      .catch((err) => {
        notification.success({ message: 'Something went wrong!' });
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    if (currentPage == Math.ceil(totalRecords / rowsPerPage)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage == 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Spin spinning={false}>
      <PageTitle title="Village Management" />
      <div className="flex justify-end mb-2">
        <Link to="/new-village">
          <button
            style={{ backgroundColor: '#2CD074' }}
            className="bg-blue-600 text-white px-2 py-1 rounded text-lg"
          >
            New Village
          </button>
        </Link>
      </div>
      <MaterialReactTable table={table} />
      {/* <div className="mt-3 mb-5 flex justify-between">
        <div className="rows-per-page flex items-center">
          <span>Rows Per Page:</span>
          <Select
            className="mx-2"
            options={[
              {
                value: "5",
                label: "5",
              },
              {
                value: "10",
                label: "10",
              },
              {
                value: "20",
                label: "20",
              },
            ]}
            onChange={(e) => {
              setRowsPerPage(e);
              setCurrentPage(1);
            }}
            value={rowsPerPage}
          />
        </div>
        <div className="flex items-center ">
          <ArrowBackIosIcon onClick={handlePrevPage} className="w-4" />
          <div>
            {currentPage}/{Math.ceil(totalRecords / parseInt(rowsPerPage))}
          </div>
          <ArrowForwardIosIcon onClick={handleNextPage} className="ml-2" />
        </div>
      </div> */}
    </Spin>
  );
};

export default Villages;
