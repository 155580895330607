import React, { createContext, useState, useContext } from 'react';

// Create context
const DrawerContext = createContext();

// Custom hook to use the Drawer context
export const useDrawer = () => useContext(DrawerContext);

// Provider component
export const DrawerProvider = ({ children }) => {
  const [open, setOpen] = useState(false); // Default state can be true or false

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  return (
    <DrawerContext.Provider value={{ open, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};
