// Notification.jsx

import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';

const StyledNotification = styled('div')`
  position: absolute;
  top: 6rem;
  right: 20px;
  background-color: #f44336;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(244, 67, 54, 0.3);
  z-index: 999;
  transition: opacity 0.5s ease;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
`;

const Notification = ({ message, onClose, visible }) => {
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [onClose, visible]);

  return <StyledNotification visible={visible}>{message}</StyledNotification>;
};

export default Notification;
