const alert_level_options = [
  {
    label: 'Critical',
    value: 'CRITICAL'
  },
  {
    label: 'High',
    value: 'HIGH'
  },
  {
    label: 'Medium',
    value: 'MEDIUM'
  },
  {
    label: 'Low',
    value: 'LOW'
  },
  {
    label: 'Informational',
    value: 'INFORMATIONAL'
  }
];

const status_options = [
  {
    label: 'Assigned',
    value: 'ASSIGNED'
  },
  {
    label: 'Open',
    value: 'OPEN'
  },
  {
    label: 'Closed',
    value: 'CLOSED'
  },
  {
    label: 'In-progress',
    value: 'IN_PROGRESS'
  }
];

const type_options = [
  {
    label: 'LOCATION_CHANGES_CDR',
    value: 'LOCATION_CHANGES_CDR'
  },
  {
    label: 'ABONRMAL_DURATION_CDR',
    value: 'ABONRMAL_DURATION_CDR'
  },
  {
    label: 'ABNORMAL_CALL_FREQUENCY_CDR',
    value: 'ABNORMAL_CALL_FREQUENCY_CDR'
  },
  {
    label: 'UNUSUAL_CALL_TIMINGS_CDR',
    value: 'UNUSUAL_CALL_TIMINGS_CDR'
  },
  {
    label: 'TOO_MANY_DEVICE_CDR',
    value: 'TOO_MANY_DEVICE_CDR'
  },
  {
    label: 'CONTACTED_CRIMINALS_CDR',
    value: 'CONTACTED_CRIMINALS_CDR'
  },
  {
    label: 'DUPLICATE_AADHAAR',
    value: 'DUPLICATE_AADHAAR'
  },
  {
    label: 'VEHICLE_DETAILS_DEVIATION',
    value: 'VEHICLE_DETAILS_DEVIATION'
  },
  {
    label: 'SUSPICIOUS_CONNECTION',
    value: 'SUSPICIOUS_CONNECTION'
  },
  {
    label: 'WANTED_DETECTED',
    value: 'WANTED_DETECTED'
  },
  {
    label: 'SUSPECT_DETECTED',
    value: 'SUSPECT_DETECTED'
  }
];

export { status_options, alert_level_options, type_options };
