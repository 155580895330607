import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Home';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TimelineIcon from '@mui/icons-material/Timeline';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import InventoryIcon from '@mui/icons-material/Inventory';
import GroupAdd from '@mui/icons-material/GroupAdd';
import Dashboard from '@mui/icons-material/Dashboard';
import ArmyLogo1 from '../Assets/army-logo-1.png';
import ArmyLogo3 from '../Assets/army-logo-3.png';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from '@mui/material';
import { ThemeContext } from '../index';
import { useMatch } from 'react-router-dom';
import { useDrawer } from '../context/drawerContext';
import { Typography } from 'antd';

function AppSidebar() {
  const { open, toggleDrawer } = useDrawer();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  // For controlling Drawer open/close state

  const menuItems = [
    { text: 'Home', route: '/', icon: <MapIcon /> },
    { text: 'Call Logs', route: '/call-logs', icon: <ContactPhoneIcon /> },
    { text: 'Anomaly', route: '/anomaly', icon: <ReportGmailerrorredIcon /> },
    { text: 'Entry/Exit', route: '/logs', icon: <AssessmentIcon /> },
    { text: 'People Journey', route: '/journey', icon: <TimelineIcon /> },
    { text: 'People Management', route: '/people', icon: <PeopleAltIcon /> },
    {
      text: 'Village Management',
      route: '/villages',
      icon: <HolidayVillageIcon />
    },
    {
      text: 'People Relationship',
      route: '/relationship',
      icon: <AccountTreeIcon />
    },
    {
      text: 'People Travel Graph',
      route: '/travel',
      icon: <ConnectingAirportsIcon />
    },
    { text: 'Inventory', route: '/inventory', icon: <InventoryIcon /> },
    { text: 'Reconciliation', route: '/reconcile', icon: <GroupAdd /> },
    { text: 'Reports', route: '/reports', icon: <Dashboard /> },
    { text: 'Auth Users', route: '/auth-users', icon: <VerifiedUserIcon /> }
  ];

  function MenuItem({ item }) {
    const isActive = location.pathname === item.route;
    console.log(isActive);

    return (
      <ListItem
        button
        onClick={() => navigate(item.route)}
        sx={{
          backgroundColor: isActive ? '#22c55e' : 'transparent', // Active state styling
          '&:hover': {
            backgroundColor: isActive ? '#22c55e' : theme.palette.action.hover
          }
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItem>
    );
  }

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          width: open ? 270 : 60, // Width changes depending on `open` state
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? 270 : 60,
            transition: 'width 0.3s',
            backgroundColor: theme.palette.mode === 'dark' ? '#191818' : 'white'
          }
        }}
      >
        <div
          style={{
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <IconButton onClick={toggleDrawer}>
            {/* Toggle Button */}
            {open ? '<<' : '>>'}
          </IconButton>
          <div
            className="flex justify-center items-center"
            style={{
              border: theme.palette.mode === 'light' && '1px solid #000000',
              backgroundColor: theme.palette.mode === 'dark' && '#4E4E4D',
              padding: '8px 16px',
              borderRadius: '84px'
            }}
          >
            <img src={ArmyLogo3} alt="logo" className="h-[3rem] w-[3rem]" />
            <img
              src={ArmyLogo1}
              alt="logo"
              className="h-[3rem] w-[3rem] ml-5"
            />
          </div>
        </div>
        <List>
          {menuItems.map((item, i) => (
            <MenuItem key={i} item={item} />
          ))}
        </List>
      </Drawer>
      {open && (
        <div
          className="absolute top-0 right-0 z-50 w-full h-full bg-white/50 backdrop-blur-sm"
          onClick={toggleDrawer}
        ></div>
      )}
    </>
  );
}

export default AppSidebar;
