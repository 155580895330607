import React, { useEffect, useState, useContext } from 'react';
import { Button, DatePicker, Select, Spin } from 'antd';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import dayjs from 'dayjs';
import moment from 'moment';
import { useAxiosInstance } from './Interceptor';
import { useNavigate } from 'react-router';
import ViewImageModal from './ViewImageModal';
import { ThemeContext } from '../index';
import PageTitle from './common/pageTitle';
const PeopleJourney = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [villageList, setVillageList] = useState([]);
  const [peopleList, setPeopleList] = useState([]);
  const [isNull, setIsNull] = useState(false);
  const [people, setPeople] = useState();
  const [loading, setLoading] = useState(false);
  const axiosInstance = useAxiosInstance();
  const [state, setState] = useState({
    q: { village_id: ['V1000'], user_id: '' },
    from_date: moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(-30, 'days')
      .add(-18.5, 'hours')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    to_date: moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(1, 'day')
      .add(5.5, 'hours')
      .add(-1, 'minutes')
      .add(59, 'seconds')
      .add(999, 'milliseconds')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  });

  const fetchJourney = async (formData) => {
    try {
      setIsNull(false);
      setLoading(true);
      const res = await axiosInstance.post(`/getUserTransaction`, formData);

      if (res?.data?.result.length === 0) {
        setIsNull(true);
        setLoading(false);
        setData([]);
        return;
      }

      setData(res?.data?.result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setData([]);
      setLoading(false);
    }
  };
  const fetchVillage = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/getVillageDetails`);
      let tempValue = [];
      res?.data?.result?.forEach((data) => {
        let obj = { label: data?.v_name, value: data?.village_id };
        tempValue.push(obj);
      });
      setVillageList(tempValue);

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const fetchUser = async (id) => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/getUserNames`, {
        village_id: id
      });
      let tempValue = [];
      res?.data?.result?.forEach((data) => {
        let obj = { label: data?.user_name, value: data?.user_id };
        tempValue.push(obj);
      });
      setPeopleList(tempValue);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVillage();
  }, []);

  useEffect(() => {
    setState({
      ...state,
      q: {
        ...state.q,
        village_id: villageList.map((elem) => elem.value)
      }
    });
  }, [villageList]);

  useEffect(() => {
    if (state?.q?.village_id) fetchUser(state?.q?.village_id);
  }, [state?.q?.village_id]);

  // useEffect(() => {
  //   onChange("V1000");
  // }, []);

  const dateHandler = (date) => {
    if (date) {
      const fromDate = moment
        .tz(date[0].toDate(), 'Asia/Kolkata')
        .add(5.5, 'hours')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const toDate = moment
        .tz(date[1].toDate(), 'Asia/Kolkata')
        .add(1, 'day')
        .add(5.5, 'hours')
        .add(-1, 'minutes')
        .add(59, 'seconds')
        .add(999, 'milliseconds')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

      setState({
        ...state,
        from_date: fromDate,
        to_date: toDate
      });
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChange = (value) => {
    console.log(value, 'values');
    // setVillage(filterData);
    setState({ ...state, q: { ...state.q, village_id: value } });
  };

  const peopleOnChange = (value) => {
    const filterData = peopleList?.find((data) => value === data?.value);
    setPeople(filterData);
    setState({ ...state, q: { ...state.q, user_id: filterData?.value } });
  };

  const onSearch = (value) => {
    // console.log("search:", value);
  };

  const submitHandler = () => {
    console.log(
      state?.q?.village_id,
      state?.q?.user_id,
      state?.from_date,
      state.to_date
    );
    if (
      // state?.q?.village_id &&
      state?.q?.user_id &&
      state?.from_date &&
      state.to_date
    ) {
      let data = {
        q: { user_id: state?.q?.user_id },
        from_date: state?.from_date,
        to_date: state.to_date
      };
      fetchJourney(data);
    }
  };
  return (
    <>
      <Spin size="large" spinning={loading}>
        <PageTitle title="Journey Timeline" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            margin: '0px 0px 15px 0px'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ fontSize: '12px', margin: '0px 0px 5px 5px' }}>
              Date Range {<span style={{ color: 'red' }}>*</span>}
            </label>{' '}
            <RangePicker
              className="dark:rounded-full rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              defaultValue={[
                dayjs(moment(new Date()).add(-1, 'month').format('YYYY-MM-DD')),
                dayjs(moment(new Date()).format('YYYY-MM-DD'))
              ]}
              format={'DD/MM/YYYY'}
              onChange={(value) => dateHandler(value)}
            />
          </div>
          <div>
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>
              Village {<span style={{ color: 'red' }}>*</span>}
            </label>{' '}
            <Select
              allowClear
              maxTagCount={'responsive'}
              showSearch
              mode="multiple"
              className="w-[15rem] rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
              value={state?.q?.village_id}
              placeholder="Select a village"
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : '',
                color: theme.palette.mode === 'dark' && '#ffffff'
              }}
              onChange={(e) => {
                if (e.includes('all')) {
                  if (state?.q?.village_id.length === villageList?.length) {
                    setState({ ...state, q: { ...state.q, village_id: [] } });
                  } else {
                    setState({
                      ...state,
                      q: {
                        ...state.q,
                        village_id: villageList.map((elem) => elem.value)
                      }
                    });
                  }
                } else {
                  setState({
                    ...state,
                    q: {
                      ...state.q,
                      village_id: e
                    }
                  });
                }
              }}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[{ value: 'all', label: 'Select All' }, ...villageList]}
            />
          </div>
          <div>
            <label style={{ fontSize: '12px', marginLeft: '5px' }}>
              People {<span style={{ color: 'red' }}>*</span>}
            </label>{' '}
            <Select
              allowClear
              showSearch
              value={people}
              placeholder="Select a People"
              onChange={peopleOnChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={peopleList}
              style={{
                background: theme.palette.mode === 'dark' ? '#374151' : '',
                color: theme.palette.mode === 'dark' && '#ffffff'
              }}
              className="rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 t"
            />
          </div>
          <Button
            style={{ marginTop: '20px', backgroundColor: '#2CD074' }}
            onClick={submitHandler}
            type="primary"
          >
            Submit
          </Button>
        </div>
        <div>
          {isNull && (
            <div className="journey-card text-[#898989]">
              No transaction records found.
            </div>
          )}
          {data?.[0]?.transactions?.length > 0 && (
            <VerticalTimeline>
              {data?.[0]?.transactions.map((dt, index) => (
                <VerticalTimelineElement
                  key={index}
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: 'rgb(138 194 238)',
                    color: 'black',
                    width: '30%',
                    marginLeft: '10rem',
                    marginRight: '10rem'
                  }}
                  contentArrowStyle={{
                    borderRight: '7px solid  rgb(138 194 238)'
                  }}
                  icon={
                    dt.imagebase64 && (
                      <ViewImageModal
                        imageClassName="rounded-full object-cover h-[4rem] w-[4rem]"
                        image={dt.imagebase64}
                        metaData={{
                          ...dt,

                          userInfo: {
                            f_name: data?.[0]?.f_name,
                            l_name: data?.[0]?.l_name
                          },
                          entry_or_exit_at: dt.date

                          // entry_or_exit_at: moment
                          //   .tz(dt.date, "Asia/Kolkata")
                          //   .format("DD,MMM, YYYY h:mm:ss A"),
                        }}
                      />
                    )
                  }
                  iconStyle={{ background: 'gray', color: '#fff' }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {dt?.date
                      ? `${
                          dt?.entry_or_exit === 'Entry' ? 'Entered' : 'Exited'
                        } ${dt?.village_name} via ${dt?.node_name}`
                      : 'NOT CAPTURED'}
                  </h3>
                  {dt.date ? (
                    <h3 style={{ color: 'red' }}>
                      {`on ${dt?.date && moment(dt?.date)?.format('DD-MM-YYYY')} (${
                        dt?.date && moment(dt?.date)?.format('dddd')
                      }), at  ${dt?.date && moment(dt?.date)?.format('hh:mm A')}`}
                    </h3>
                  ) : null}
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          )}

          {!data?.[0]?.transactions?.length > 0 && !isNull && (
            <div className="journey-card text-[#898989]">
              Please select the Village and People to see the Journey
            </div>
          )}
        </div>
      </Spin>
    </>
  );
};

export default PeopleJourney;
