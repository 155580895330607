import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import React from 'react';

const PageTitle = ({ title }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h3"
      gutterBottom
      sx={{
        fontWeight: 'bold',
        color: '#3B82F6',
        textAlign: 'left',
        mb: theme.spacing(2),
        fontSize: '1.875rem',
        fontFamily: 'system-ui, sans-serif'
      }}
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
