import { useEffect } from 'react';
import L from 'leaflet';
import 'Leaflet.TileLayer.MBTiles';

const MBTilesLayer = ({ map }) => {
  useEffect(() => {
    if (map) {
      // Add MBTiles layers to the map
      console.log(map);
      console.log(L.tileLayer.mbTiles);
      console.log(L.tileLayer.mbTiles);
      L.tileLayer
        .mbTiles('/data/ocm_kash_10.mbtiles', {
          minZoom: 10,
          maxZoom: 10
        })
        .addTo(map);
      console.log(map);
      L.tileLayer
        .mbTiles('/data/ocm_kash_11.mbtiles', {
          minZoom: 11,
          maxZoom: 11
        })
        .addTo(map);

      L.tileLayer
        .mbTiles('/data/ocm_kash_12.mbtiles', {
          minZoom: 12,
          maxZoom: 12
        })
        .addTo(map);

      L.tileLayer
        .mbTiles('/data/ocm_kash_13.mbtiles', {
          minZoom: 13,
          maxZoom: 13
        })
        .addTo(map);

      L.tileLayer
        .mbTiles('/data/ocm_kash_14.mbtiles', {
          minZoom: 14,
          maxZoom: 14
        })
        .addTo(map);

      map.setMinZoom(10);
      map.setMaxZoom(14);
    }
  }, [map]);

  return <div id="map" style={{ height: '100vh' }} />;
};

export default MBTilesLayer;
