import React from 'react';
import { Modal, Image } from 'antd';
import VillagerProfileAI from '../Assets/VillagerProfileAI.png';

const PopupModal = ({ visible, onClose }) => {
  return (
    <Modal
      title="Prediction AI"
      visible={visible}
      onCancel={onClose}
      footer={null} // Remove the footer to hide the close button
      width={800} // Set the width of the modal
    >
      {/* Use Ant Design Image component for optimized image loading */}
      <Image
        src={VillagerProfileAI}
        alt="Predict AI"
        preview={false}
        style={{ width: '100%', height: '100%' }}
      />
    </Modal>
  );
};

export default PopupModal;
