import React, { createContext, useRef } from 'react';

const ParentRefContext = createContext(null);

const ParentRefProvider = ({ children }) => {
  const parentDivRef = useRef(null);

  return (
    <ParentRefContext.Provider value={parentDivRef}>
      {children}
    </ParentRefContext.Provider>
  );
};

export { ParentRefContext, ParentRefProvider };
